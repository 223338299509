/*
 * 1.DOM 操作 API
*/
import Bus from './bus'

const uuidv1 = require('uuid/v1')

// 根据 html 代码片段创建 dom 对象
function createElemByHTML (html) {
  let div = void 0
  div = document.createElement('div')
  div.innerHTML = html
  return div.children
}

// 是否是 DOM List
function isDOMList (selector) {
  if (!selector) {
    return false
  }
  if (selector instanceof HTMLCollection || selector instanceof NodeList) {
    return true
  }
  return false
}

// 封装 document.querySelectorAll
function querySelectorAll (selector) {
  let result = document.querySelectorAll(selector)
  if (isDOMList(result)) {
    return result
  } else {
    return [result]
  }
}

// 记录所有的事件绑定
let eventList = []

// 创建构造函数
function DomElement (selector) {
  if (!selector) {
    return
  }

  // selector 本来就是 DomElement 对象，直接返回
  if (selector instanceof DomElement) {
    return selector
  }

  this.selector = selector
  let nodeType = selector.nodeType

  // 根据 selector 得出的结果（如 DOM，DOM List）
  let selectorResult = []
  if (nodeType === 9) {
    // document 节点
    selectorResult = [selector]
  } else if (nodeType === 1) {
    // 单个 DOM 节点
    selectorResult = [selector]
  } else if (isDOMList(selector) || selector instanceof Array) {
    // DOM List 或者数组
    selectorResult = selector
  } else if (typeof selector === 'string') {
    // 字符串
    selector = selector.replace('/\n/mg', '').trim()
    if (selector.indexOf('<') === 0) {
      // 如 <div>
      selectorResult = createElemByHTML(selector)
    } else {
      // 如 #id .class
      selectorResult = querySelectorAll(selector)
    }
  }

  let length = selectorResult.length
  if (!length) {
    // 空数组
    return this
  }

  // 加入 DOM 节点
  let i = void 0
  for (i = 0; i < length; i++) {
    this[i] = selectorResult[i]
  }
  this.length = length
}

// 修改原型
DomElement.prototype = {
  constructor: DomElement,

  // 类数组，forEach
  forEach: function forEach (fn) {
    let i = void 0
    for (i = 0; i < this.length; i++) {
      let elem = this[i]
      let result = fn.call(elem, elem, i)
      if (result === false) {
        break
      }
    }
    return this
  },

  // clone
  clone: function clone (deep) {
    let cloneList = []
    this.forEach(function (elem) {
      cloneList.push(elem.cloneNode(!!deep))
    })
    return $(cloneList)
  },

  // 获取第几个元素
  get: function get (index) {
    let length = this.length
    if (index >= length) {
      index = index % length
    }
    return $(this[index])
  },

  // 第一个
  first: function first () {
    return this.get(0)
  },

  // 最后一个
  last: function last () {
    let length = this.length
    return this.get(length - 1)
  },

  // 绑定事件
  on: function on (type, selector, fn) {
    // selector 不为空，证明绑定事件要加代理
    if (!fn) {
      fn = selector
      selector = null
    }

    // type 是否有多个
    let types = []
    types = type.split(/\s+/)

    return this.forEach(function (elem) {
      types.forEach(function (type) {
        if (!type) {
          return
        }

        // 记录下，方便后面解绑
        eventList.push({
          elem: elem,
          type: type,
          fn: fn
        })

        if (!selector) {
          // 无代理
          elem.addEventListener(type, fn)
          return
        }

        // 有代理
        elem.addEventListener(type, function (e) {
          var target = e.target
          if (target.matches(selector)) {
            fn.call(target, e)
          }
        })
      })
    })
  },

  // 取消事件绑定
  off: function off (type, fn) {
    return this.forEach(function (elem) {
      elem.removeEventListener(type, fn)
    })
  },

  // 获取/设置 属性
  attr: function attr (key, val) {
    if (val == null) {
      // 获取值
      return this[0].getAttribute(key)
    } else {
      // 设置值
      return this.forEach(function (elem) {
        elem.setAttribute(key, val)
      })
    }
  },

  // 添加 class
  addClass: function addClass (className) {
    if (!className) {
      return this
    }
    return this.forEach(function (elem) {
      let arr = void 0
      if (elem.className) {
        // 解析当前 className 转换为数组
        arr = elem.className.split(/\s/)
        arr = arr.filter(function (item) {
          return !!item.trim()
        })
        // 添加 class
        if (arr.indexOf(className) < 0) {
          arr.push(className)
        }
        // 修改 elem.class
        elem.className = arr.join(' ')
      } else {
        elem.className = className
      }
    })
  },

  // 删除 class
  removeClass: function removeClass (className) {
    if (!className) {
      return this
    }
    return this.forEach(function (elem) {
      let arr = void 0
      if (elem.className) {
        // 解析当前 className 转换为数组
        arr = elem.className.split(/\s/)
        arr = arr.filter(function (item) {
          item = item.trim()
          // 删除 class
          if (!item || item === className) {
            return false
          }
          return true
        })
        // 修改 elem.class
        elem.className = arr.join(' ')
      }
    })
  },

  // 修改 css
  css: function css (key, val) {
    let currentStyle = key + ':' + val + ';'
    return this.forEach(function (elem) {
      let style = (elem.getAttribute('style') || '').trim()
      let styleArr = void 0,
        resultArr = []
      if (style) {
        // 将 style 按照 ; 拆分为数组
        styleArr = style.split(';')
        styleArr.forEach(function (item) {
          // 对每项样式，按照 : 拆分为 key 和 value
          let arr = item.split(':').map(function (i) {
            return i.trim()
          })
          if (arr.length === 2) {
            resultArr.push(arr[0] + ':' + arr[1])
          }
        })
        // 替换或者新增
        resultArr = resultArr.map(function (item) {
          if (item.indexOf(key) === 0) {
            return currentStyle
          } else {
            return item
          }
        })
        if (resultArr.indexOf(currentStyle) < 0) {
          resultArr.push(currentStyle)
        }
        // 结果
        elem.setAttribute('style', resultArr.join('; '))
      } else {
        // style 无值
        elem.setAttribute('style', currentStyle)
      }
    })
  },

  // 显示
  show: function show () {
    return this.css('display', 'block')
  },

  // 隐藏
  hide: function hide () {
    return this.css('display', 'none')
  },

  // 获取子节点
  children: function children () {
    let elem = this[0]
    if (!elem) {
      return null
    }

    return $(elem.children)
  },

  // 获取子节点（包括文本节点）
  childNodes: function childNodes () {
    let elem = this[0]
    if (!elem) {
      return null
    }

    return $(elem.childNodes)
  },

  // 增加子节点
  append: function append ($children) {
    return this.forEach(function (elem) {
      $children.forEach(function (child) {
        elem.appendChild(child)
      })
    })
  },

  // 移除当前节点
  remove: function remove () {
    return this.forEach(function (elem) {
      if (elem.remove) {
        elem.remove()
      } else {
        let parent = elem.parentElement
        parent && parent.removeChild(elem)
      }
    })
  },

  // 是否包含某个子节点
  isContain: function isContain ($child) {
    let elem = this[0]
    let child = $child[0]
    return elem.contains(child)
  },

  // 尺寸数据
  getSizeData: function getSizeData () {
    let elem = this[0]
    return elem.getBoundingClientRect() // 可得到 bottom height left right top width 的数据
  },

  // 封装 nodeName
  getNodeName: function getNodeName () {
    let elem = this[0]
    return elem.nodeName
  },

  // 从当前元素查找
  find: function find (selector) {
    let elem = this[0]
    return $(elem.querySelectorAll(selector))
  },

  // 获取当前元素的 text
  text: function text (val) {
    if (!val) {
      // 获取 text
      let elem = this[0]
      return elem.innerHTML.replace(/<.*?>/g, function () {
        return ''
      })
    } else {
      // 设置 text
      return this.forEach(function (elem) {
        elem.innerHTML = val
      })
    }
  },

  // 获取 html
  html: function html (value) {
    let elem = this[0]
    if (value == null) {
      return elem.innerHTML
    } else {
      elem.innerHTML = value
      return this
    }
  },

  // 获取 value
  val: function val () {
    let elem = this[0]
    return elem.value.trim()
  },

  // focus
  focus: function focus () {
    return this.forEach(function (elem) {
      elem.focus()
    })
  },

  // parent
  parent: function parent () {
    let elem = this[0]
    return $(elem.parentElement)
  },

  // parentUntil 找到符合 selector 的父节点
  parentUntil: function parentUntil (selector, _currentElem) {
    let results = document.querySelectorAll(selector)
    let length = results.length
    if (!length) {
      // 传入的 selector 无效
      return null
    }

    let elem = _currentElem || this[0]
    if (elem.nodeName === 'BODY') {
      return null
    }

    let parent = elem.parentElement
    let i = void 0
    for (i = 0; i < length; i++) {
      if (parent === results[i]) {
        // 找到，并返回
        return $(parent)
      }
    }

    // 继续查找
    return this.parentUntil(selector, parent)
  },

  // 判断两个 elem 是否相等
  equal: function equal ($elem) {
    if ($elem.nodeType === 1) {
      return this[0] === $elem
    } else {
      return this[0] === $elem[0]
    }
  },

  // 将该元素插入到某个元素前面
  insertBefore: function insertBefore (selector) {
    let $referenceNode = $(selector)
    let referenceNode = $referenceNode[0]
    if (!referenceNode) {
      return this
    }
    return this.forEach(function (elem) {
      let parent = referenceNode.parentNode
      parent.insertBefore(elem, referenceNode)
    })
  },

  // 将该元素插入到某个元素后面
  insertAfter: function insertAfter (selector) {
    let $referenceNode = $(selector)
    let referenceNode = $referenceNode[0]
    if (!referenceNode) {
      return this
    }
    return this.forEach(function (elem) {
      let parent = referenceNode.parentNode
      if (parent.lastChild === referenceNode) {
        // 最后一个元素
        parent.appendChild(elem)
      } else {
        // 不是最后一个元素
        parent.insertBefore(elem, referenceNode.nextSibling)
      }
    })
  }
}

// new 一个对象
export const $ = (selector) => {
  return new DomElement(selector)
}

// 解绑所有事件，用于销毁编辑器
$.offAll = function () {
  eventList.forEach(function (item) {
    let elem = item.elem
    let type = item.type
    let fn = item.fn
    // 解绑
    elem.removeEventListener(type, fn)
  })
}

/**
 * 2 选区操作
 */

// 实时保存选取
export const _saveRangeRealTime = (element) => {
  // 按键后保存
  element.onkeyup = function () {
    saveRange()
  },
    element.onmousedown = function () {
      // mousedown 状态下，鼠标滑动到编辑区域外面，也需要保存选区
      element.onmouseleave = function () {
        saveRange()
      }
    },
    element.onmouseup = function () {
      saveRange()
      // 在编辑器区域之内完成点击，取消鼠标滑动到编辑区外面的事件
      element.onmouseleave = function () {
        saveRange()
      }
    }
}

let _currentRange = null

// 获取 range 对象
function getRange () {
  return _currentRange
}

// 保存选区
function saveRange (_range) {
  if (_range) {
    // 保存已有选区
    _currentRange = _range
    return
  }

  // 获取当前的选区
  let selection = window.getSelection()
  if (selection.rangeCount === 0) {
    return
  }
  let range = selection.getRangeAt(0)

  // 判断选区内容是否在编辑内容之内
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  // 判断选区内容是否在不可编辑区域之内

  // if ($containerElem.attr('contenteditableld') === 'false' &&  !$containerElem.parentUntil('[contenteditableld=false]')) {
  //   return
  // }
  // 是编辑内容之内的
  let result = {
    isbold: false,
    isfontsizesmall: false,
    isfontsizemiddle: false,
    isfontsizebig: false,
    isitalic: false,
    isunderline: false,
    isjustifyleft: false,
    isjustifycenter: false,
    isjustifyright: false,
    showInsertImg: false,
    showIsertVideo: false
  }
  if (($containerElem.attr('contenteditableld') === 'true') || $containerElem.parentUntil('[contenteditableld=true]')) {
    _currentRange = range
    let showInsertImg = false
    let showIsertVideo = false
    if (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]')) {
      showInsertImg = true
      showIsertVideo = true
    }
    result = {
      isbold: isBold(),
      isfontsizesmall: isFontSizeSmall(),
      isfontsizemiddle: isFontSizeMiddle(),
      isfontsizebig: isFontSizeBig(),
      isitalic: isItalic(),
      isunderline: isUnderLine(),
      isjustifyleft: isJustifyLeft(),
      isjustifycenter: isJustifyCenter(),
      isjustifyright: isJustifyRight(),
      showInsertImg: showInsertImg,
      showIsertVideo: showIsertVideo
    }
  }

  Bus.$emit('menusstatus', result)
}

export const refreshmenustatus = () => {
  let showInsertImg = false
  let showIsertVideo = false
  let selection = window.getSelection()
  if (selection.rangeCount !== 0) {
    let range = selection.getRangeAt(0)
    // 判断选区内容是否在编辑内容之内
    let $containerElem = getSelectionContainerElem(range)
    if ($containerElem && (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]'))) {
      showInsertImg = true
      showIsertVideo = true
    }
  }
  let result = {
    isbold: isBold(),
    isfontsizesmall: isFontSizeSmall(),
    isfontsizemiddle: isFontSizeMiddle(),
    isfontsizebig: isFontSizeBig(),
    isitalic: isItalic(),
    isunderline: isUnderLine(),
    isjustifyleft: isJustifyLeft(),
    isjustifycenter: isJustifyCenter(),
    isjustifyright: isJustifyRight(),
    showInsertImg: showInsertImg,
    showIsertVideo: showIsertVideo
  }
  Bus.$emit('menusstatus', result)
}

// 折叠选区
export const collapseRange = (toStart) => {
  if (toStart == null) {
    // 默认为 false
    toStart = false
  }
  let range = _currentRange
  if (range) {
    range.collapse(toStart)
  }
}
// 选中区域的文字
export const getSelectionText = () => {
  let range = _currentRange
  if (range) {
    return _currentRange.toString()
  } else {
    return ''
  }
}

// 选区的 $Elem
function getSelectionContainerElem (range) {
  range = range || _currentRange
  let elem = void 0
  if (range) {
    elem = range.commonAncestorContainer
    return $(elem.nodeType === 1 ? elem : elem.parentNode)
  }
}

export const getSelectionStartElem = (range) => {
  range = range || _currentRange
  let elem = void 0
  if (range) {
    elem = range.startContainer
    return $(elem.nodeType === 1 ? elem : elem.parentNode)
  }
}
export const getSelectionEndElem = (range) => {
  range = range || _currentRange
  let elem = void 0
  if (range) {
    elem = range.endContainer
    return $(elem.nodeType === 1 ? elem : elem.parentNode)
  }
}
// 选区是否为空
export const isSelectionEmpty = () => {
  let range = _currentRange
  if (range && range.startContainer) {
    if (range.startContainer === range.endContainer) {
      if (range.startOffset === range.endOffset) {
        return true
      }
    }
  }
  return false
}
// 恢复选区
export const restoreSelection = () => {
  let selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(_currentRange)
}
// 创建一个空白（即 &#8203 字符）选区
export const createEmptyRange = () => {
  let range = getRange()

  if (!range) {
    // 当前无 range
    return
  }
  if (!isSelectionEmpty()) {
    // 当前选区必须没有内容才可以
    return
  }
  try {
    // 目前只支持 webkit 内核
    if (UA.isWebkit()) {
      // 插入 &#8203
      // 修改 offset 位置
      range.setEnd(range.endContainer, range.endOffset + 1)
      // 存储
      saveRange(range)
    }
  } catch (ex) {
    // 部分情况下会报错，兼容一下
  }
}
// 根据 $Elem 设置选区
export const createRangeByElem = ($elem, toStart, isContent) => {
  // $elem - 经过封装的 elem
  // toStart - true 开始位置，false 结束位置
  // isContent - 是否选中Elem的内容
  if (!$elem.length) {
    return
  }

  let elem = $elem[0]
  let range = document.createRange()

  if (isContent) {
    range.selectNodeContents(elem)
  } else {
    range.selectNode(elem)
  }

  if (typeof toStart === 'boolean') {
    range.collapse(toStart)
  }

  // 存储 range
  this.saveRange(range)
}

/*
 *  3.粘贴信息的处理
*/

// 获取粘贴的纯文本
export const getPasteText = (e) => {
  let clipboardData = e.clipboardData || e.originalEvent && e.originalEvent.clipboardData
  let pasteText = void 0
  if (clipboardData == null) {
    pasteText = window.clipboardData && window.clipboardData.getData('text')
  } else {
    pasteText = clipboardData.getData('text/plain')
  }

  return replaceHtmlSymbol(pasteText)
}
// 获取粘贴的html
export const getPasteHtml = (e, filterStyle, ignoreImg) => {
  let clipboardData = e.clipboardData || e.originalEvent && e.originalEvent.clipboardData
  let pasteText = void 0,
    pasteHtml = void 0
  if (clipboardData == null) {
    pasteText = window.clipboardData && window.clipboardData.getData('text')
  } else {
    pasteText = clipboardData.getData('text/plain')
    pasteHtml = clipboardData.getData('text/html')
  }
  if (!pasteHtml && pasteText) {
    pasteHtml = replaceHtmlSymbol(pasteText)
  }
  if (!pasteHtml) {
    return
  }
  // 过滤word中状态过来的无用字符
  let docSplitHtml = pasteHtml.split('</html>')
  if (docSplitHtml.length === 2) {
    pasteHtml = docSplitHtml[0]
  }
  // 过滤无用标签
  pasteHtml = pasteHtml.replace(/<(meta|script|link).+?>/igm, '')
  // 去掉注释
  pasteHtml = pasteHtml.replace(/<!--.*?-->/mg, '')
  // 过滤 data-xxx 属性
  pasteHtml = pasteHtml.replace(/\s?data-.+?=('|").+?('|")/igm, '')
  if (ignoreImg) {
    // 忽略图片
    pasteHtml = pasteHtml.replace(/<img.+?>/igm, '')
  }
  if (filterStyle) {
    // 过滤样式
    pasteHtml = pasteHtml.replace(/\s?(class|style)=('|").*?('|")/igm, '')
  } else {
    // 保留样式
    pasteHtml = pasteHtml.replace(/\s?class=('|").*?('|")/igm, '')
  }

  return '<p style="font-size:13px">' + pasteHtml + '</p>'
}

// 获取粘贴的图片文件
export const getPasteImgs = (e) => {
  let result = []
  let txt = getPasteText(e)
  if (txt) {
    // 有文字，就忽略图片
    return result
  }
  let clipboardData = e.clipboardData || e.originalEvent && e.originalEvent.clipboardData || {}
  let items = clipboardData.items
  if (!items) {
    return result
  }
  objForEach(items, function (key, value) {
    let type = value.type
    if (/image/i.test(type)) {
      result.push(value.getAsFile())
    }
  })
  return result
}

/*
 *   4.编辑区域
*/

// 获取一个 elem.childNodes 的 JSON 数据
export const getChildrenJSON = ($elem) => {
  let result = []
  let $children = $elem.childNodes() || [] // 注意 childNodes() 可以获取文本节点
  $children.forEach(function (curElem) {
    let elemResult = void 0
    let nodeType = curElem.nodeType
    // 文本节点
    if (nodeType === 3) {
      elemResult = curElem.textContent
      elemResult = replaceHtmlSymbol(elemResult)
    }
    // 普通 DOM 节点
    if (nodeType === 1) {
      elemResult = {}
      // tag
      elemResult.tag = curElem.nodeName.toLowerCase()
      // attr
      let attrData = []
      let attrList = curElem.attributes || {}
      let attrListLength = attrList.length || 0
      for (let i = 0; i < attrListLength; i++) {
        let attr = attrList[i]
        attrData.push({
          name: attr.name,
          value: attr.value
        })

      }
      elemResult.attrs = attrData
      // children（递归）
      elemResult.children = getChildrenJSON($(curElem))
    }

    result.push(elemResult)
  })
  return result
}

/**
 * 5. 光标位置
 */

/**
 *  6. 工具
 */
// 和 UA 相关的属性
let UA = {
  _ua: navigator.userAgent,
  // 是否 webkit
  isWebkit: function isWebkit () {
    let reg = /webkit/i
    return reg.test(this._ua)
  },
  // 是否 IE
  isIE: function isIE () {
    return 'ActiveXObject' in window
  }
}

// 遍历对象
function objForEach (obj, fn) {
  let key = void 0,
    result = void 0
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      result = fn.call(obj, key, obj[key])
      if (result === false) {
        break
      }
    }
  }
}

// 遍历类数组
export const arrForEach = (fakeArr, fn) => {
  let i = void 0,
    item = void 0,
    result = void 0
  let length = fakeArr.length || 0
  for (i = 0; i < length; i++) {
    item = fakeArr[i]
    result = fn.call(fakeArr, item, i)
    if (result === false) {
      break
    }
  }
}
// 获取随机数
export const getRandom = (prefix) => {
  return prefix + Math.random().toString().slice(2)
}

// 替换 html 特殊字符
function replaceHtmlSymbol (html) {
  if (html == null) {
    return ''
  }
  return html.replace(/</gm, '&lt;').replace(/>/gm, '&gt;').replace(/"/gm, '&quot;').replace(/(\r\n|\r|\n)/g, '<br/>')
}

// 判断是不是 function
export const isFunction = (fn) => {
  return typeof fn === 'function'
}

/**
 * 7. 编辑框具体操作
 */
// 粗体
export const bold = () => {
  // 选择范围
  let range = getRange()
  let txt = range.extractContents().textContent
  let length = txt.length
  if (length === 0) {
    return
  }
  // 删除被选择的内容
  range.deleteContents()
  // 创建新的元素 strong
  let bold = document.createElement('strong')
  // 设置 strong 内容
  bold.innerHTML = txt
  // 在被选择的位置添加加粗的元素
  range.insertNode(bold)
  _currentRange = null
}

window.picarray = []

export const insertimages = (paths) => {
  let range = getRange()
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  if (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]')) {
    if (paths && paths.length) {
      let uuid = uuidv1()
      let imgdiv = paths.map((res, index) => {
        return '<li style="float: left;width:' + 100 / paths.length + '%;height: 386px"><img onclick="{window.console.log(\'' + index + '\')}" class="showimg" style="width: 100%;height: 386px" src=' + res + '></li>'
      }).join('')
      let number = paths.length
      let html = '<div contenteditable="false"><p><div id=' + uuid + ' style="width: 100%;height: 386px;overflow: hidden;position: relative;top: 0;left: 0" >' +
        '<ul id="' + uuid + 'A" style="width:' + 100 * paths.length + '%;height: 386px;list-style: none;position: absolute;top: 0;left: 0;" data-v="0" number="' + number + '">' + imgdiv + '</ul>' +
        '<div style="display: flex;height: 100%;width: 40px;justify-content: center;align-items: center;position: absolute;top: 0;left: 0">' +
        '<span style="display: inline-block;height: 20px;width: 20px;border-width: 0 0 2px 2px;border-color: #fff;border-style: solid;' +
        'transform: matrix(0.71, 0.71, -.71, 0.71, 0, 0);-webkit-transform: matrix(0.71, 0.71, -.71, 0.71, 0, 0);" ' +
        'onclick="{let trul=document.getElementById(\'' + uuid + 'A\');' +
        'console.log(trul);' +
        'let datav = trul.getAttribute(\'data-v\');' +
        'let number = trul.getAttribute(\'number\');' +
        'datav = datav< number?datav:number-1;' +
        'console.log(trul.clientWidth);' +
        'let ll = datav *  trul.clientWidth / number;' +
        'console.log(ll);' +
        'trul.style.left= -ll + \'px\' ;' +
        'datav = parseInt(datav) + 1;' +
        'trul.setAttribute(\'data-v\',datav)}">' +
        '</span>' +
        ' </div>' +
        '<div style="display: flex;height: 100%;width: 40px;justify-content: center;align-items: center;position: absolute;top: 0;right: 0">' +
        '<span style="display: inline-block;height: 20px;width: 20px;border-width:  2px 2px 0 0;border-color: #fff;border-style: solid;' +
        'transform: matrix(0.71, 0.71, -.71, 0.71, 0, 0);-webkit-transform: matrix(0.71, 0.71, -.71, 0.71, 0, 0);"' +
        'onclick="{let trul=document.getElementById(\'' + uuid + 'A\');' +
        'let datav = trul.getAttribute(\'data-v\');' +
        'let number = trul.getAttribute(\'number\');' +
        'datav = datav>=0?datav:0;' +
        'console.log(trul.clientWidth);' +
        'let ll = datav *  trul.clientWidth / number;' +
        'console.log(ll);' +
        'trul.style.left= -ll + \'px\' ;' +
        'datav = parseInt(datav) - 1;' +
        'trul.setAttribute(\'data-v\',datav)}">' +
        '</span>' +
        ' </div>' +
        '</div></p><br></div>'
      let $Element = $(html)[0]
      range.insertNode($Element)
      window.picarray.push({
        id: uuid,
        paths: paths,
        selectindex: 0
      })
      console.log('window.picarray:', window.picarray)
      // restoreSelection()
      // range.selectAllChildren($Element);
      // range.collapseToEnd();
      _currentRange = null
    }
  }
}

export const onitemclick = (e) => {
  document.write('Hello World!')
}

export const insertimge = (path) => {
  let range = getRange()
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  if (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]')) {
    if (path) {
      let html = '<div><p><img style="width: 100%" src=' + path + '></p><br></div>'
      let $Element = $(html)[0]
      range.insertNode($Element)
      // restoreSelection()
      // range.selectAllChildren($Element);
      // range.collapseToEnd();
      // _currentRange = null
    }
  }
}

export const insertvideo = (path) => {
  let range = getRange()
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  if (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]')) {
    if (path) {
      let html = '<div><p><video controls style="width: 100%" src=' + path + '></p><br></div>'
      range.insertNode($(html)[0])
      _currentRange = null
    }
  }
}

export const insertHtml = (shtml) => {
  let range = getRange()
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  // if (($containerElem.attr('imginsert') === 'true') || $containerElem.parentUntil('[imginsert=true]')) {
  document.execCommand('styleWithCSS', false, true)
  let html = '<div><p>' + shtml + '</p></div>'
  document.execCommand('insertHTML', false, html)
  // }
}
// 斜体
export const italic = () => {
  // 选择范围
  let range = getRange()
  let txt = range.extractContents().textContent
  let length = txt.length
  if (length === 0) {
    return
  }
  // 删除被选择的内容
  range.deleteContents()
  // 创建新的元素
  let italic = document.createElement('span')
  italic.style.cssText = 'font-style: italic;'
  italic.innerHTML = txt
  // 在被选择的位置添加粗体的元素
  range.insertNode(italic)
  _currentRange = null
}

export const color = (colorsa) => {
  // 选择范围
  let range = getRange()
  let $containerElem = getSelectionContainerElem(range)
  if (!$containerElem) {
    return
  }
  restoreSelection()
  document.execCommand('styleWithCSS', false, true)
  document.execCommand('ForeColor', false, colorsa)
  // let txt = range.extractContents().textContent
  // let length = txt.length
  // if (length === 0) {
  //   return
  // }
  // // 删除被选择的内容
  // range.deleteContents()
  // // 创建新的元素
  // let colors = document.createElement('span')
  // colors.style.cssText = 'color: ' + colorsa
  // colors.innerHTML = txt
  // // 在被选择的位置添加粗体的元素
  // range.insertNode(colors)
}
// 下划线
export const underline = () => {
  // 选择范围
  let range = getRange()
  let txt = range.extractContents().textContent
  let length = txt.length
  if (length === 0) {
    return
  }
  // 删除被选择的内容
  range.deleteContents()
  // 创建新的元素 u
  let underline = document.createElement('u')
  underline.innerHTML = txt
  // 在被选择的位置添加下划线的元素
  range.insertNode(underline)
  _currentRange = null
}
// 字体
export const fontSize = (size) => {
  // 选择范围
  let range = getRange()
  let txt = range.extractContents().textContent
  let length = txt.length
  if (length === 0) {
    return
  }
  // 删除被选择的内容
  range.deleteContents()
  // 创建新的元素
  let fontSize = document.createElement('span')
  fontSize.style.cssText = 'font-size: ' + size + 'px;'
  fontSize.innerHTML = txt
  // 在被选择的位置添加字体大小的元素
  range.insertNode(fontSize)
  _currentRange = null
}
// 对齐方式
export const justifying = (justifying, content) => {
  let str = ''
  if (content.indexOf('text-align') !== -1) {
    if (content.indexOf('text-align: left') !== -1) {
      str = content.replace('left', justifying)
    } else if (content.indexOf('text-align: right') !== -1) {
      str = content.replace('right', justifying)
    } else if (content.indexOf('text-align: center') !== -1) {
      str = content.replace('center', justifying)
    }
  } else {
    str = '<div style="text-align: ' + justifying + '">' + content + '</div>'
  }
  return str
}

// 获取光标所在位置的字体样式
export const isFontSizeSmall = () => {
  let fontSize = document.queryCommandValue('FontSize')
  if (fontSize === '1') {
    return true
  }
  return false
}
export const isFontSizeMiddle = () => {
  let fontSize = document.queryCommandValue('FontSize')
  if (fontSize === '2') {
    return true
  }
  return false
}
export const isFontSizeBig = () => {
  let fontSize = document.queryCommandValue('FontSize')
  if (fontSize === '3') {
    return true
  }
  return false
}
// 是否加粗
export const isBold = () => {
  return document.queryCommandValue('bold') === 'true'

}
// 是否是斜体
export const isItalic = () => {
  return document.queryCommandValue('italic') === 'true'
}

export const isUnderLine = () => {
  return document.queryCommandValue('underline') === 'true'
}

export const isJustifyLeft = () => {
  return document.queryCommandValue('justifyLeft') === 'true'
}

export const isJustifyCenter = () => {
  return document.queryCommandValue('justifyCenter') === 'true'
}

export const isJustifyRight = () => {
  return document.queryCommandValue('justifyRight') === 'true'
}
