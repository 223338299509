<template>
  <div @click="handleClick">
    <input :accept="accept"
           :multiple="multiple"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <slot></slot>
  </div>
</template>

<script>
import OssUpload from '../../utils/videoossupload'
import config from '../../config/constantconfig'

const uuidv1 = require('uuid/v1')

export default {
  name: 'VideoUpload',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'video/*'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    funcallback: {
      type: Function
    },
    uploadpath: {
      type: String,
      default: 'test/'
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    handleClick () {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) {
        return
      }
      this.uploadFiles(files)
      this.$refs.input.value = null
    },
    uploadFiles (files) {
      let postFiles = Array.prototype.slice.call(files)
      if (!this.multiple) postFiles = postFiles.slice(0, 1)
      if (postFiles.length === 0) return
      let resultarray = []
      this.$emit('uploadstart')
      postFiles.forEach(file => {
        let url = URL.createObjectURL(file)
        this.upload(file).then(res => {
          res.localUrl = url
          resultarray.push(res)
          let progressdata = {
            finshnum: resultarray.length,
            totalnum: postFiles.length
          }
          this.$emit('uploadprogress', progressdata)
          if (resultarray.length === postFiles.length) {
            this.$emit('uploadSuccess', resultarray)
          }
        })
      })
    },
    upload (file) {
      let fun = this.funcallback
      let uuid = uuidv1()
      let uploadPath = this.getUploadPath()
      let name = this.getFileSuffix(file.name)
      return OssUpload.multipartUpload(uploadPath + uuid + name, file, fun).then(r1 => {
        let domain = config.videoOssInfo.url
        let resultinfo = {
          localfile: file,
          res: r1,
          videoUrl: 'https://' + domain + '/' + r1.url
        }
        return new Promise((resolve, reject) => {
          resolve(resultinfo)
        })
      }, () => {
        let errorinfo = {
          localfile: file,
          videoUrl: ''
        }
        return new Promise((resolve, reject) => {
          resolve(errorinfo)
        })
      })
    },
    getUploadPath () {
      let uploadPath = config.projectName + this.uploadpath
      return uploadPath
    },
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
