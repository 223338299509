<template>
  <div>
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :value="visible"
            :width="width"
            inner
            scrollables>
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div @click.stop="onClickClose"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-back"
                    size="32"></Icon>
              返回
            </div>
            <div @click.stop="onClickEdit"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-edit"
                    size="32"></Icon>
              编辑
            </div>
            <div @click.stop="onClickDelete"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-delete"
                    size="32"></Icon>
              删除
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="detail-left">
            <!-- 商品名称 农药名称 总有效成分及含量 -->
            <div class="flex">
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>商品名称:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{goodsName}}
                </div>
              </div>
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>农药名称:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{pestName}}
                </div>
              </div>
              <div>
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>总有效成分及含量:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{element}}
                </div>
              </div>
            </div>
            <!-- 登记证号 剂型 毒性 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>登记证号:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{regNumber}}
                </div>
              </div>
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>剂型:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{dosageValName}}
                </div>
              </div>
              <div>
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>毒性:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{toxicityName}}
                </div>
              </div>
            </div>
            <!-- 农药类别 保质期 持证企业 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>农药类别:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{typeValName}}
                </div>
              </div>
              <div class="m-r-65">
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>保质期:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">{{expirationDate}}
                </div>
              </div>
              <div>
                <div class="detail-title">
                  <span style="color:#D0021B">*</span>
                  <span>登记证持有人:</span>
                </div>
                <div class="detail-value"
                     style="width:280px">
                  {{entName}}
                </div>
              </div>
            </div>
            <!-- 商品规格 -->
            <div class="m-t-15">
              <div class="detail-title">
                <!-- <span style="color:#D0021B">*</span> -->
                <span>商品规格:</span>
              </div>
              <Table :columns="specDetailColums"
                     :data="specList"
                     border
                     class="m-t-5"
                     width="970">
                <template slot="goodsPic"
                          slot-scope="{ row,index }">
                  <div class="text-center">
                    <template v-if="row.coverList.length>0">
                      <img :src="getDefaultCover(row.coverList).url" style="width:80px;height:60px">
                      <!--<Icon @click.stop="showSpecCover(index)"-->
                      <!--class="cursor-pointer"-->
                      <!--color="#0652DD"-->
                      <!--custom="i-icon icon-image"-->
                      <!--size="28"></Icon>-->
                    </template>
                    <template v-else>
                      <Icon color="#CED5E2"
                            custom="i-icon icon-image"
                            size="28"></Icon>
                    </template>
                  </div>
                </template>
              </Table>
            </div>
            <!-- 有效成分信息 -->
            <div class="m-t-15">
              <div class="detail-title">
                <!-- <span style="color:#D0021B">*</span> -->
                <span>有效成分信息:</span>
              </div>
              <Table :columns="eleDetailColums"
                     :data="eleList"
                     border
                     class="m-t-5"
                     width="970">
              </Table>
            </div>
            <!-- 使用方法 -->
            <div class="m-t-15">
              <div class="detail-title">
                <!-- <span style="color:#D0021B">*</span> -->
                <span>制剂用药量信息:</span>
              </div>
              <Table :columns="methodDetailColums"
                     :data="methodList"
                     border
                     class="m-t-5"
                     width="970">
                <template slot="uploadPic"
                          slot-scope="{ row,index }">
                  <div class="text-center">
                    <template v-if="row.coverList.length>0">
                      <img :src="getDefaultCover(row.coverList).url" style="width:80px;height:60px">
                      <!--<Icon @click.stop="showMethodCover(index)"-->
                      <!--class="cursor-pointer"-->
                      <!--color="#0652DD"-->
                      <!--custom="i-icon icon-image"-->
                      <!--size="28"></Icon>-->
                    </template>
                    <template v-else>
                      <Icon color="#CED5E2"
                            custom="i-icon icon-image"
                            size="28"></Icon>
                    </template>
                  </div>
                </template>
              </Table>
            </div>
            <!-- 使用技术 -->
            <div class="m-t-15">
              <div class="detail-title">
                <span style="color:#D0021B">*</span>
                <span>使用技术:</span>
              </div>
              <div class="detail-value m-t-5"
                   style="width:1060px">
                {{technology}}
              </div>
            </div>
            <!-- 产品性能 -->
            <div class="m-t-15">
              <div class="detail-title">
                <span style="color:#D0021B">*</span>
                <span>产品性能:</span>
              </div>
              <div class="detail-value m-t-5"
                   style="width:1060px">
                {{property}}
              </div>
            </div>
            <!-- 注意事项 -->
            <div class="m-t-15">
              <div class="detail-title">
                <span style="color:#D0021B">*</span>
                <span>注意事项:</span>
              </div>
              <div class="detail-value m-t-5"
                   style="width:1060px">
                {{notice}}
              </div>
            </div>
            <!-- 中毒急救 -->
            <div class="m-t-15">
              <div class="detail-title">
                <span style="color:#D0021B">*</span>
                <span>中毒急救:</span>
              </div>
              <div class="detail-value m-t-5"
                   style="width:1060px">
                {{firstaid}}
              </div>
            </div>
            <!-- 存储运输 -->
            <div class="m-t-15">
              <div class="detail-title">
                <span style="color:#D0021B">*</span>
                <span>存储运输:</span>
              </div>
              <div class="detail-value m-t-5"
                   style="width:1060px">
                {{transport}}
              </div>
            </div>
          </div>
          <div class="detail-right m-l-60">
            <!-- 图片 视频 -->
            <div>
              <div class="detail-title">
                <span>商品主图:</span>
              </div>
              <div class="detail-upload">
                <template v-if="coverList.length===0">
                  <Icon custom="i-icon icon-upload_pic"
                        size="60"></Icon>
                  <span>未上传主图</span>
                </template>
                <template v-else>
                  <div @click.stop="showCover"
                       class="detail-upload-img">
                    <img :src="getDefaultCover(coverList).url">
                    <span>{{coverList.length}}张</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="m-t-30">
              <div class="detail-title">
                <span>商品主视频:</span>
              </div>
              <div class="detail-upload">
                <template v-if="!video.videoUrl">
                  <Icon custom="i-icon icon-upload_video"
                        size="60"></Icon>
                  <span>未上传视频</span>
                </template>
                <template v-else>
                  <div class="detail-upload-img">
                    <VideoPlayer :poster="video.coverUrl"
                                 :videoUrl="video.videoUrl"
                                 aspectRatio="240:180"></VideoPlayer>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
    <!-- 商品主图弹窗 -->
    <Modal @on-visible-change="onUploadCoverClose"
           footer-hide
           title="查看图片"
           v-model="showCoverVisible"
           width="560">
      <div class="uploadPic">
        <ImageListComponent :data="imageList"
                            disabled></ImageListComponent>
      </div>
    </Modal>
    <!-- 用药量图片弹窗 -->
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :value="showMethodCoverVisible"
            :width="width"
            inner
            scrollables>
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div @click.stop="onClickCancleMethodCover"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-back"
                    size="32"></Icon>
              返回
            </div>
          </div>
        </div>
        <div class="method-upload">
          <ImageListComponent :data="imageList"
                              @on-click-item="onClickMethodCoverItem"
                              disabled></ImageListComponent>
          <ul class="method-upload-ul flex-1 m-l-50"
              v-if="showMethodCoverVisible&&imageList.length>0">
            <li class="method-upload-li">
              <div class="method-upload-title">
                <span style="color:#D0021B">*</span>
                <span>标题:</span>
              </div>
              <div class="method-upload-value">{{imageList[methodCoverIndex].title}}</div>
            </li>
            <li class="method-upload-li">
              <div class="method-upload-title">
                <span style="color:#D0021B">*</span>
                <span>描述:</span>
              </div>
              <div class="method-upload-richText h-300"
                   v-html="imageList[methodCoverIndex].desc.content"></div>
            </li>
          </ul>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {getGoodsDetail} from '../../services/goodsapi'
import VideoPlayer from '../../components/common/VideoPlayer'
import ImageListComponent from '../../components/common/ImageListComponent'

export default {
  components: {VideoPlayer, ImageListComponent},
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    goodsId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:goodsId', 0)
        this.clearCreateGoods()
      }
    },
    goodsId (newVal) {
      if (newVal && this.visible) {
        this.getGoodsDetail()
      } else {
        this.$emit('update:goodsId', 0)
        this.clearCreateGoods()
      }
    }
  },
  data () {
    return {
      coverList: [],
      imageList: [],
      video: {
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      },
      goodsName: '',
      pestName: '',
      element: '',
      regNumber: '',
      dosageValName: '',
      toxicityName: '',
      typeValName: '',
      expirationDate: '',
      entName: '',
      specList: [
        {
          coverList: [],
          create: true
        }
      ],
      eleList: [
        {
          element: '',
          content: ''
        }
      ],
      methodList: [
        {
          coverList: [],
          crop: '',
          preventObject: '',
          dosage: '',
          method: '',
        }
      ],
      property: '',
      technology: '',
      notice: '',
      firstaid: '',
      transport: '',
      specDetailColums: [
        {
          title: '规格图片',
          width: 110,
          slot: 'goodsPic',
        },
        {
          title: '规格',
          minWidth: 300,
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '规格')
            ])
          },
          render: (h, params) => {
            let row = params.row
            let text = row.desc ? row.desc + '*' + row.dosage + row.dosageUnitName + '/' + row.numberUnitName : row.dosage + row.dosageUnitName + '/' + row.numberUnitName
            return h('span', text)
          }
        },
        {
          title: '价格',
          minWidth: 300,
          render: (h, params) => {
            let row = params.row
            let text = row.price ? row.price + '元/' + row.numberUnitName : '0.00'
            return h('span', text)
          }
        }
      ],
      eleDetailColums: [
        {
          title: '有效成分',
          minWidth: 400,
          key: 'element',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '有效成分')
            ])
          }
        },
        {
          title: '有效成分含量',
          minWidth: 400,
          key: 'content',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '有效成分含量')
            ])
          }
        }
      ],
      methodDetailColums: [
        {
          title: '图例',
          width: 110,
          slot: 'uploadPic',
        },
        {
          title: '作物/场所',
          minWidth: 180,
          key: 'crop',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '作物/场所')
            ])
          }
        },
        {
          title: '防治对象',
          minWidth: 180,
          key: 'preventObject',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '防治对象')
            ])
          }
        },
        {
          title: '用药量(制剂量/亩)',
          minWidth: 180,
          key: 'dosage',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '用药量(制剂量/亩)')
            ])
          }
        },
        {
          title: '施用方法',
          minWidth: 180,
          key: 'method',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '施用方法')
            ])
          }
        }
      ],
      showCoverVisible: false,
      showMethodCoverVisible: false,
      methodCoverIndex: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 获取默认图
    getDefaultCover (arr) {
      for (const item of arr) {
        if (item.isDefault) {
          return item
        }
      }
      return arr[0]
    },
    // 展示主图
    showCover () {
      this.imageList = JSON.parse(JSON.stringify(this.coverList))
      this.showCoverVisible = true
    },
    // 获取规格图片
    showSpecCover (index) {
      this.imageList = JSON.parse(JSON.stringify(this.specList[index].coverList))
      this.showCoverVisible = true
    },
    // 获取用药量图片
    showMethodCover (index) {
      this.imageList = JSON.parse(JSON.stringify(this.methodList[index].coverList))
      this.showMethodCoverVisible = true
    },
    // 点击用药量图片
    onClickMethodCoverItem (index) {
      this.methodCoverIndex = index
    },
    // 当上传图片弹窗关闭时
    onUploadCoverClose (status) {
      if (!status) {
        this.imageList = []
      }
    },
    // 点击取消
    onClickCancleMethodCover () {
      this.imageList = []
      this.showMethodCoverVisible = false
    },
    // 清空新增商品
    clearCreateGoods () {
      this.coverList = []
      this.imageList = []
      this.video = {
        id: 0,
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      }
      this.goodsName = ''
      this.pestName = ''
      this.element = ''
      this.regNumber = ''
      this.dosageValName = ''
      this.toxicityName = ''
      this.typeValName = ''
      this.expirationDate = ''
      this.specList = [
        {
          coverList: [],
          create: true
        }
      ]
      this.eleList = [
        {
          element: '',
          content: ''
        }
      ]
      this.methodList = [
        {
          coverList: [],
          crop: '',
          preventObject: '',
          dosage: '',
          method: '',
        }
      ]
      this.property = ''
      this.technology = ''
      this.notice = ''
      this.firstaid = ''
      this.transport = ''
      this.entName = ''
    },
    // 获取商品详情
    getGoodsDetail () {
      getGoodsDetail(this.goodsId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.goodsId = data.id
          data.coverList.forEach(item => {
            if (item.coverType === 1) {
              this.coverList.push({
                url: item.url || '',
                width: item.width || '',
                height: item.height || '',
                isDefault: item.extension ? JSON.parse(item.extension).isDefault : ''
              })
            } else {
              this.video = {
                videoTitle: item.title || '',
                videoUrl: item.url || '',
                coverUrl: item.coverImgUrl || ''
              }
            }
          })
          // this.coverList = data.coverList.map(item => {
          //   return {
          //     url: item.url,
          //     width: item.width,
          //     height: item.height,
          //     isDefault: item.extension ? JSON.parse(item.extension).isDefault : ''
          //   }
          // })
          // if (data.descList.length > 0) {
          //   this.video = {
          //     videoTitle: data.descList[0].content[0].title || '',
          //     videoUrl: data.descList[0].content[0].url || '',
          //     coverUrl: data.descList[0].content[0].coverImgUrl || ''
          //   }
          // }
          this.goodsName = data.name || ''
          this.pestName = data.extra.name || ''
          this.element = data.extra.mainIngredient || ''
          this.regNumber = data.extra.serial || ''
          this.dosageValName = data.extra.dosageForm || ''
          this.toxicityName = data.extra.toxicity || ''
          this.typeValName = data.extra.type || ''
          this.expirationDate = data.shelfLife || ''
          if (data.specList.length > 0) {
            this.specList = data.specList.map(item => {
              return {
                coverList: item.coverList.map(items => {
                  return {
                    url: items.url,
                    width: items.width,
                    height: items.height,
                    isDefault: items.extension ? JSON.parse(items.extension).isDefault : ''
                  }
                }),
                desc: item.childSpecPack || '',
                dosage: item.qty || '',
                dosageUnitName: item.unit || '',
                numberUnitName: item.packageUnit || '',
                price: item.salePrice || ''
              }
            })
          }
          if (data.extra.ingredientList.length > 0) {
            this.eleList = data.extra.ingredientList.map(item => {
              return {
                element: item.ingredient || '',
                content: item.content || ''
              }
            })
          }
          if (data.extra.dosageList.length > 0) {
            this.methodList = data.extra.dosageList.map(item => {
              return {
                coverList: item.descList.map(items => {
                  return {
                    url: items.content[0].url || '',
                    width: items.content[0].width || '',
                    height: items.content[0].height || '',
                    title: items.content[0].title || '',
                    desc: {
                      content: items.content[0].desc || ''
                    },
                    isDefault: items.content[0].extension ? JSON.parse(items.content[0].extension).isDefault : false
                  }
                }),
                crop: item.applyCrop || '',
                preventObject: item.preventObject || '',
                dosage: item.dosage || '',
                method: item.applyMethod || '',
              }
            })
          }
          this.property = data.extra.performance || ''
          this.technology = data.extra.useMethod || ''
          this.notice = data.extra.notice || ''
          this.firstaid = data.extra.poison || ''
          this.transport = data.extra.store || ''
          if (data.holderList.length > 0) {
            this.entName = data.holderList[0].entName || ''
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
    },
    // 点击编辑
    onClickEdit () {
      this.$emit('on-click-edit')
    },
    onClickDelete () {
      this.$emit('on-click-delete')
    }
  },
}
</script>

<style lang='scss' scoped>
  .main {
    height: 100%;
    background-color: #f9f9ff;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 20px;

      .topbar-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: #666;

        &:hover {
          color: #0652dd;
        }
      }

      ::v-deep {
        .ivu-select-selection,
        .ivu-input {
          background-color: transparent;
          border-color: #9c9ccd;
          border-radius: 18px;
        }

        .ivu-select-selection span {
          padding-left: 12px;
        }

        .ivu-input {
          padding: 4px 12px;
        }
      }
    }
  }

  .detail {
    height: calc(100% - 160px);
    overflow-y: scroll;
    background-color: #fff;
    display: flex;
    margin: 20px;
    padding: 25px;

    .detail-title {
      font-size: 14px;
      line-height: 32px;
      color: #0652dd;
    }

    .detail-value {
      min-height: 32px;
      font-size: 14px;
      line-height: 32px;
      padding: 0 10px;
      border-bottom: 1px solid #d4d3e9;
    }

    ::v-deep .ivu-table .ivu-table-header th {
      background-color: #e4edfe;
    }
  }

  .detail-upload {
    width: 240px;
    height: 180px;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
    }

    .detail-upload-img {
      width: 100%;
      height: 100%;
      position: relative;

      span {
        position: absolute;
        right: 8px;
        bottom: 8px;
        padding: 0 16px;
        line-height: 28px;
        color: #fff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 16px;
      }

      .detail-upload-mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .detail-upload-del {
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .uploadPic {
    padding-left: 20px;

    .uploadPic-title {
      font-size: 16px;
    }

    .uploadPic-btn {
      display: flex;
      justify-content: center;
    }
  }

  ::v-deep .ivu-select-dropdown-list {
    max-height: 300px;
  }

  ::v-deep .ivu-drawer {
    top: 64px;
    z-index: 1008;

    .ivu-drawer-body {
      padding: 0;
    }
  }

  .method-upload {
    height: calc(100% - 160px);
    background-color: #fff;
    overflow-y: scroll;
    margin: 20px;
    padding: 20px;
    display: flex;

    .method-upload-ul {
      .method-upload-li {
        display: flex;
        margin-bottom: 20px;

        .method-upload-title {
          flex-shrink: 0;
          font-size: 16px;
          line-height: 36px;
          margin-right: 10px;
        }

        .method-upload-value {
          width: 100%;
          line-height: 36px;
          padding: 0 10px;
          border: 1px solid #d8d8d8;
        }

        .method-upload-richText {
          width: 100%;
          padding: 7px;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }
</style>
