<template>
  <div>
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :value="visible"
            :width="width"
            inner
            scrollables>
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div @click.stop="onClickClose"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-back"
                    size="32"></Icon>
              返回
            </div>
            <div @click.stop="onClickClear"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-uniE904"
                    size="32"></Icon>
              清空
            </div>
            <Button @click="onClickConfirm"
                    style="border-radius: 17px;"
                    type="primary">保存
            </Button>
          </div>
        </div>
        <div class="create">
          <div class="create-left">
            <!-- 商品名称 农药名称 总有效成分及含量 -->
            <div class="flex">
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>商品名称:</span>
                </div>
                <Input maxlength="20"
                       placeholder="商品名称"
                       style="width:280px"
                       v-model="goodsName"/>
              </div>
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>农药名称:</span>
                </div>
                <Input maxlength="20"
                       placeholder="农药名称"
                       style="width:280px"
                       v-model="pestName"/>
              </div>
              <div>
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>总有效成分及含量:</span>
                </div>
                <Input maxlength="20"
                       placeholder="总有效成分及含量"
                       style="width:280px"
                       v-model="element"/>
              </div>
            </div>
            <!-- 登记证号 剂型 毒性 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>登记证号:</span>
                </div>
                <Input maxlength="20"
                       placeholder="登记证号"
                       style="width:280px"
                       v-model="regNumber"/>
              </div>
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>剂型:</span>
                </div>
                <Select @on-change="onChangeDosageVal"
                        label-in-value
                        placeholder="剂型"
                        style="width:280px"
                        transfer
                        v-model="dosageVal">
                  <Option :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          v-for="item in dosageValArr">{{ item.name }}
                  </Option>
                </Select>
              </div>
              <div>
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>毒性:</span>
                </div>
                <Select @on-change="onChangeToxicity"
                        label-in-value
                        placeholder="毒性"
                        style="width:280px"
                        transfer
                        v-model="toxicity">
                  <Option :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          v-for="item in toxicityArr">{{item.name}}
                  </Option>
                </Select>
              </div>
            </div>
            <!-- 农药类别 保质期 持证企业 -->
            <div class="flex m-t-15">
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>农药类别:</span>
                </div>
                <Select @on-change="onChangeTypeVal"
                        label-in-value
                        placeholder="农药类别"
                        style="width:280px"
                        transfer
                        v-model="typeVal">
                  <Option :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          v-for="item in typeValArr">{{item.name}}
                  </Option>
                </Select>
              </div>
              <div class="m-r-65">
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>保质期:</span>
                </div>
                <Input @on-keyup="expirationDate=formatNumber(expirationDate)"
                       maxlength="3"
                       placeholder="保质期"
                       style="width:280px"
                       v-model="expirationDate">
                  <Select @on-change="onChangeExpirationDateUnit"
                          label-in-value
                          slot="append"
                          style="width: 70px"
                          transfer
                          v-model="expirationDateUnit">
                    <Option :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            v-for="item in expirationDateUnitArr">{{item.name}}
                    </Option>
                  </Select>
                </Input>
              </div>
              <div>
                <div class="create-title">
                  <span style="color:#D0021B">*</span>
                  <span>登记证持有人:</span>
                </div>
                <Input maxlength="20"
                       placeholder="登记证持有人"
                       style="width:280px"
                       v-model="entName"/>
              </div>
            </div>
            <!-- 商品规格 -->
            <div class="m-t-15">
              <div class="create-title">
                <span>商品规格:</span>
              </div>
              <Table :columns="specColums"
                     :data="specList"
                     border
                     class="m-t-5"
                     width="970">
                <template slot="goodsPic"
                          slot-scope="{ row,index }">
                  <div @click.stop="row.create?'':uploadSpecCover(index)"
                       class="create-upload m-v-8"
                       style="width:80px;height:60px">
                    <template v-if="row.coverList.length===0">
                      <Icon custom="i-icon icon-upload_pic"
                            size="25"></Icon>
                      <span>上传图片</span>
                    </template>
                    <template v-else>
                      <img :src="getDefaultCover(row.coverList).url">
                    </template>
                  </div>
                </template>
                <template slot="spec"
                          slot-scope="{ row,index }">
                  <div class="flex a-center">
                    <span style="color:#0652DD"
                          v-if="row.create">点击右侧“+”设置商品规格</span>
                    <span @click.stop="onClickEditSpec(index)"
                          style="color:#0652DD;cursor: pointer;"
                          v-else>{{row.desc?row.desc+'*'+row.dosage+row.dosageUnitName+'/'+row.numberUnitName:row.dosage+row.dosageUnitName+'*'+row.numberUnitName}}</span>
                    <span style="color:#D0021B;margin-left:10px"
                          v-if="row.noStandard">规格不符合标准</span>
                  </div>
                </template>
                <template slot="price"
                          slot-scope="{ row,index }">
                  <span style="color:#999"
                        v-if="row.create">0.00</span>
                  <span v-else>{{row.price?row.price+'元/'+row.numberUnitName:'0.00'}}</span>
                </template>
                <template slot="operation"
                          slot-scope="{ row,index }">
                  <Icon @click.stop="onClickAddSpec"
                        class="m-r-10 cursor-pointer"
                        color="#0652DD"
                        custom="i-icon icon-adds"
                        size="24"></Icon>
                  <Icon @click.stop="onClickDeleteSpec(index)"
                        class="cursor-pointer"
                        color="#E04C53"
                        custom="i-icon icon-remove"
                        size="24"
                        v-if="!row.create"></Icon>
                </template>
              </Table>
            </div>
            <!-- 有效成分信息 -->
            <div class="m-t-15">
              <div class="create-title">
                <!-- <span style="color:#D0021B">*</span> -->
                <span>有效成分信息:</span>
              </div>
              <Table :columns="eleColums"
                     :data="eleList"
                     border
                     class="m-t-5"
                     width="970">
                <template slot="element"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="有效成分"
                         style="width:100%"
                         v-model="eleList[index].element"/>
                </template>
                <template slot="content"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="有效成分含量"
                         style="width:100%"
                         v-model="eleList[index].content"/>
                </template>
                <template slot="operation"
                          slot-scope="{ row,index }">
                  <Icon @click.stop="addEle"
                        class="m-r-10 cursor-pointer"
                        color="#0652DD"
                        custom="i-icon icon-adds"
                        size="24"></Icon>
                  <Icon @click.stop="eleList.splice(index,1)"
                        class="cursor-pointer"
                        color="#E04C53"
                        custom="i-icon icon-remove"
                        size="24"
                        v-if="eleList.length>1"></Icon>
                </template>
              </Table>
            </div>
            <!-- 使用方法 -->
            <div class="m-t-15">
              <div class="create-title">
                <!-- <span style="color:#D0021B">*</span> -->
                <span>制剂用药量信息:</span>
              </div>
              <Table :columns="methodColums"
                     :data="methodList"
                     border
                     class="m-t-5"
                     width="970">
                <template slot="uploadPic"
                          slot-scope="{ row,index }">
                  <div @click.stop="uploadMethodCover(index)"
                       class="create-upload m-v-8"
                       style="width:80px;height:60px">
                    <template v-if="row.coverList.length===0">
                      <Icon custom="i-icon icon-upload_pic"
                            size="25"></Icon>
                      <span>上传图片</span>
                    </template>
                    <template v-else>
                      <img :src="getDefaultCover(row.coverList).url">
                    </template>
                  </div>
                </template>
                <template slot="crop"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="适用作物"
                         style="width:100%"
                         v-model="methodList[index].crop"/>
                </template>
                <template slot="preventObject"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="防治对象"
                         style="width:100%"
                         v-model="methodList[index].preventObject"/>
                </template>
                <template slot="dosage"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="用药量/亩"
                         style="width:100%"
                         v-model="methodList[index].dosage"/>
                </template>
                <template slot="method"
                          slot-scope="{ row,index }">
                  <Input maxlength="40"
                         placeholder="施用方法"
                         style="width:100%"
                         v-model="methodList[index].method"/>
                </template>
                <template slot="operation"
                          slot-scope="{ row,index }">
                  <Icon @click.stop="addMethod"
                        class="m-r-10 cursor-pointer"
                        color="#0652DD"
                        custom="i-icon icon-adds"
                        size="24"></Icon>
                  <Icon @click.stop="methodList.splice(index,1)"
                        class="cursor-pointer"
                        color="#E04C53"
                        custom="i-icon icon-remove"
                        size="24"
                        v-if="methodList.length>1"></Icon>
                </template>
              </Table>
            </div>
            <!-- 产品性能 -->
            <div class="m-t-15">
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>使用技术:</span>
              </div>
              <Input :autosize="{minRows: 4,maxRows: 999}"
                     class="m-t-5"
                     placeholder="请输入使用技术"
                     style="width:970px"
                     type="textarea"
                     v-model="technology"/>
            </div>
            <!-- 产品性能 -->
            <div class="m-t-15">
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>产品性能:</span>
              </div>
              <Input :autosize="{minRows: 4,maxRows: 999}"
                     class="m-t-5"
                     placeholder="请输入产品性能"
                     style="width:970px"
                     type="textarea"
                     v-model="property"/>
            </div>
            <!-- 注意事项 -->
            <div class="m-t-15">
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>注意事项:</span>
              </div>
              <Input :autosize="{minRows: 4,maxRows: 999}"
                     class="m-t-5"
                     placeholder="请输入注意事项"
                     style="width:970px"
                     type="textarea"
                     v-model="notice"/>
            </div>
            <!-- 中毒急救 -->
            <div class="m-t-15">
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>中毒急救:</span>
              </div>
              <Input :autosize="{minRows: 4,maxRows: 999}"
                     class="m-t-5"
                     placeholder="请输入中毒急救措施"
                     style="width:970px"
                     type="textarea"
                     v-model="firstaid"/>
            </div>
            <!-- 存储运输 -->
            <div class="m-t-15">
              <div class="create-title">
                <span style="color:#D0021B">*</span>
                <span>存储运输:</span>
              </div>
              <Input :autosize="{minRows: 4,maxRows: 999}"
                     class="m-t-5"
                     placeholder="请输入存储运输方法"
                     style="width:970px"
                     type="textarea"
                     v-model="transport"/>
            </div>
            <div class="h-30"></div>
          </div>
          <div class="create-right m-l-60">
            <!-- 图片 视频 -->
            <div>
              <div class="create-title">
                <span>商品主图:</span>
              </div>
              <div @click.stop="uploadCover"
                   class="create-upload">
                <template v-if="coverList.length===0">
                  <Icon custom="i-icon icon-upload_pic"
                        size="60"></Icon>
                  <span>上传主图</span>
                </template>
                <template v-else>
                  <div class="create-upload-img">
                    <img :src="getDefaultCover(coverList).url">
                    <span>{{coverList.length}}张</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="m-t-30">
              <div class="create-title">
                <span>商品主视频:</span>
              </div>
              <div @click.stop="uploadVideo"
                   class="create-upload">
                <template v-if="!video.videoUrl">
                  <Icon custom="i-icon icon-upload_video"
                        size="60"></Icon>
                  <span>上传视频</span>
                </template>
                <template v-else>
                  <div class="create-upload-img">
                    <img :src="video.coverUrl||getVideoCover(video.videoUrl)">
                    <div class="create-upload-mask">
                      <Icon class="create-upload-play"
                            color="#fff"
                            custom="i-icon icon-player"
                            size="48"></Icon>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
    <!-- 商品主图弹窗 -->
    <Modal @on-visible-change="onUploadCoverClose"
           footer-hide
           title="上传图片"
           v-model="uploadCoverVisible"
           width="560">
      <div class="uploadPic">
        <div class="uploadPic-title m-b-10">
          <p>图片最多可上传5张。</p>
          <p>第一张图片默认为产品主图</p>
        </div>
        <ImageListComponent :count="5-imageList.length"
                            :data="imageList"
                            @upload-success="uploadCoverSuccess"
                            uploadpath="/goods/goods/"></ImageListComponent>
        <div class="uploadPic-btn">
          <Button @click.stop="onClickConfirmCover"
                  class="m-r-20"
                  type="primary">确认
          </Button>
          <Button @click.stop="onClickCancleCover">取消</Button>
        </div>
      </div>
    </Modal>
    <!-- 商品主视频弹窗 -->
    <Modal footer-hide
           title="上传视频"
           v-model="uploadVideoVisible"
           width="720">
      <div class="uploadVideo">
        <div class="uploadVideo-con">
          <Input placeholder="视频标题"
                 style="width:100%"
                 v-model="videoDemo.videoTitle"/>
          <div class="flex m-t-25">
            <VideoUpload :disabled="uploadVideoDisabled"
                         :funcallback="getUploadVideoProgress"
                         @uploadSuccess="uploadVideoSuccess"
                         @uploadstart="uploadVideoStart"
                         uploadpath="/goods/goods/">
              <div class="create-upload m-r-25">
                <template v-if="!videoDemo.videoUrl">
                  <template v-if="!uploadVideoProgress">
                    <Icon custom="i-icon icon-upload_video"
                          size="60"></Icon>
                    <span>上传视频</span>
                  </template>
                  <template v-else>
                    <Progress :percent="uploadVideoProgress"
                              class="m-l-20"/>
                  </template>
                </template>
                <template v-else>
                  <div class="create-upload-img">
                    <img :src="getVideoCover(videoDemo.videoUrl)">
                    <div class="create-upload-mask">
                      <Icon class="create-upload-play"
                            color="#fff"
                            custom="i-icon icon-player"
                            size="48"></Icon>
                    </div>
                    <Icon @click.stop="videoDemo.videoUrl=''"
                          class="create-upload-del"
                          color="#D0021B"
                          custom="i-icon icon-delete_pic"
                          size="24"></Icon>
                  </div>
                </template>
              </div>
            </VideoUpload>
            <ImgUpload @uploadSuccess="uploadVideoCoverSuccess"
                       uploadpath="/goods/goods/">
              <div class="create-upload">
                <template v-if="!videoDemo.coverUrl">
                  <Icon custom="i-icon icon-upload_pic"
                        size="60"></Icon>
                  <span>上传封面</span>
                </template>
                <template v-else>
                  <div class="create-upload-img">
                    <img :src="videoDemo.coverUrl">
                    <Icon @click.stop="videoDemo.coverUrl=''"
                          class="create-upload-del"
                          color="#D0021B"
                          custom="i-icon icon-delete_pic"
                          size="24"></Icon>
                  </div>
                </template>
              </div>
            </ImgUpload>
          </div>
        </div>
        <div class="uploadVideo-btn">
          <Button @click.stop="onClickConfirmVideo"
                  class="m-r-25"
                  type="primary">确认
          </Button>
          <Button @click.stop="onClickCancleVideo">取消</Button>
        </div>
      </div>
    </Modal>
    <!-- 规格图片弹窗 -->
    <Modal @on-visible-change="onUploadCoverClose"
           footer-hide
           title="上传图片"
           v-model="uploadSpecCoverVisible"
           width="560">
      <div class="uploadPic">
        <ImageListComponent :data="imageList"
                            @upload-success="uploadCoverSuccess"
                            uploadpath="/goods/goods/"></ImageListComponent>
        <div class="uploadPic-btn">
          <Button @click.stop="onClickConfirmSpecCover"
                  class="m-r-20"
                  type="primary">确认
          </Button>
          <Button @click.stop="onClickCancleSpecCover">取消</Button>
        </div>
      </div>
    </Modal>
    <!-- 规格弹窗 -->
    <Modal footer-hide
           title="规格设置"
           v-model="specVisible"
           width="720">
      <div class="specModal">
        <ul class="specModal-ul">
          <li class="specModal-li">
            <div class="flex a-center">
              <div class="specModal-title">最小剂量单位:</div>
              <Input @on-keyup="specObj.dosage=formatFloat(specObj.dosage)"
                     maxlength="6"
                     placeholder="剂量"
                     style="width:140px"
                     v-model="specObj.dosage">
                <Select @on-change="specObj.dosageUnitName=$event.label"
                        label-in-value
                        slot="append"
                        style="width: 60px"
                        transfer
                        v-model="specObj.dosageUnit">
                  <Option :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          v-for="item in dosageUnitArr">{{ item.name }}
                  </Option>
                </Select>
              </Input>
              <span class="fs-28 m-h-10">=</span>
              <Select @on-change="onChangeDoseSpecUnit"
                      label-in-value
                      style="width: 60px"
                      transfer
                      v-model="specObj.numberUnit">
                <Option :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        v-for="item in numberUnitArr">{{ item.name }}
                </Option>
              </Select>
            </div>
            <div class="flex a-center j-between m-t-15">
              <div class="flex a-center">
                <div class="specModal-title">价格:</div>
                <Input @on-keyup="specObj.price=formatFloat(specObj.price)"
                       maxlength="8"
                       placeholder="价格"
                       style="width:140px"
                       v-model="specObj.price">
                <span slot="append"
                      style="display:inline-block;width:45px">元/{{specObj.numberUnitName}}</span>
                </Input>
              </div>
              <div class="specModal-opea flex a-center">
                <Icon @click.stop="onClickAddDoseSpec"
                      class="m-r-10 cursor-pointer"
                      color="#0652DD"
                      custom="i-icon icon-adds"
                      size="24"
                      v-if="specObjList.length===0"></Icon>
              </div>
            </div>
          </li>
          <li :key="index"
              class="specModal-li"
              v-for="(item,index) in specObjList">
            <div class="flex a-center">
              <div class="specModal-title">{{toChinesNum(index+2)}}级包装单位:</div>
              <span>{{getSpecDesc(index)}}</span>
              <span class="m-h-10">*</span>
              <Input @on-keyup="item.dosage=formatNumber(item.dosage)"
                     maxlength="4"
                     placeholder="数量"
                     style="width:140px"
                     v-model="item.dosage">
              <span slot="append"
                    style="display:inline-block;width:45px">{{item.dosageUnitName}}</span>
              </Input>
              <span class="fs-28 m-h-10">=</span>
              <Select @on-change="($event)=>{onChangePackSpecUnit($event,item,index)}"
                      label-in-value
                      style="width: 60px"
                      transfer
                      v-model="item.numberUnit">
                <Option :key="items.id"
                        :label="item.name"
                        :value="items.id"
                        v-for="items in numberUnitArr">{{ items.name }}
                </Option>
              </Select>
            </div>
            <div class="flex a-center j-between m-t-15">
              <div class="flex a-center">
                <div class="specModal-title">价格:</div>
                <Input @on-keyup="item.price=formatFloat(item.price)"
                       maxlength="8"
                       placeholder="价格"
                       style="width:140px"
                       v-model="item.price">
                <span slot="append"
                      style="display:inline-block;width:45px">元/{{item.numberUnitName}}</span>
                </Input>
              </div>
              <div class="specModal-opea flex a-center">
                <Icon @click.stop="onClickAddPackSpec(item,index)"
                      class="m-r-10 cursor-pointer"
                      color="#0652DD"
                      custom="i-icon icon-adds"
                      size="24"
                      v-if="index===(specObjList.length-1)"></Icon>
                <Icon @click.stop="onClickDeleteSpecChild(item,index)"
                      class="cursor-pointer"
                      color="#E04C53"
                      custom="i-icon icon-remove"
                      size="24"></Icon>
              </div>
            </div>
          </li>
        </ul>
        <div class="specModal-btn">
          <Button @click.stop="onClickConfirmSpec"
                  class="m-r-20"
                  type="primary">确认
          </Button>
          <Button @click.stop="onClickCancleSpec">取消</Button>
        </div>
      </div>
    </Modal>
    <!-- 用药量图片弹窗 -->
    <Drawer :closable="false"
            :mask="false"
            :mask-closable="false"
            :transfer="false"
            :value="uploadMethodCoverVisible"
            :width="width"
            inner
            scrollables>
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div @click.stop="onClickCancleMethodCover"
                 class="topbar-btn m-r-40">
              <Icon class="m-r-8"
                    custom="i-icon icon-back"
                    size="32"></Icon>
              返回
            </div>
            <Button @click="onClickConfirmMethodCover"
                    style="border-radius: 17px;"
                    type="primary">保存
            </Button>
          </div>
        </div>
        <div class="method-upload">
          <ImageListComponent :data="imageList"
                              @on-click-item="onClickMethodCoverItem"
                              @upload-success="uploadMethodCoverSuccess"
                              uploadpath="/goods/goods/"></ImageListComponent>
          <ul class="method-upload-ul flex-1 m-l-50"
              v-if="uploadMethodCoverVisible&&imageList.length>0">
            <li class="method-upload-li">
              <div class="method-upload-title">
                <span style="color:#D0021B">*</span>
                <span>标题:</span>
              </div>
              <input v-model="imageList[methodCoverIndex].title">
            </li>
            <li class="method-upload-li">
              <div class="method-upload-title">
                <span style="color:#D0021B">*</span>
                <span>描述:</span>
              </div>
              <rich-text-edit :child="imageList[methodCoverIndex].desc"
                              class="w-full h-300 p-v-7"
                              picurl="course/about/">
              </rich-text-edit>
            </li>
          </ul>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  createGoods,
  editGoods,
  getGoodsDetail,
  getLicenseDosageList,
  getLicenseTypeList,
  verifySpec
} from '../../services/goodsapi'
import ImageListComponent from '../../components/common/ImageListComponent'
import ImgUpload from '../../components/common/ImgUpload'
import VideoUpload from '../../components/common/VideoUpload'
import RichTextEdit from '../../components/common/RichTextEdit'

const uuidv1 = require('uuid/v1')
export default {
  components: {ImageListComponent, ImgUpload, VideoUpload, RichTextEdit},
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    goodsId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:goodsId', 0)
        this.clearCreateGoods()
      }
    },
    goodsId (newVal) {
      if (newVal && this.visible) {
        this.getGoodsDetail()
      } else {
        this.$emit('update:goodsId', 0)
        this.clearCreateGoods()
      }
    }
  },
  data () {
    return {
      libraryId: 0,
      uploadCoverVisible: false,
      uploadVideoVisible: false,
      coverList: [],
      imageList: [],
      video: {
        id: 0,
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      },
      videoDemo: {
        id: 0,
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      },
      uploadVideoDisabled: false,
      uploadVideoProgress: 0,
      goodsName: '',
      pestName: '',
      element: '',
      regNumber: '',
      dosageVal: 0,
      dosageValName: '',
      dosageValArr: [],
      toxicity: 0,
      toxicityName: '',
      toxicityArr: [
        {
          id: 1,
          name: '微毒'
        },
        {
          id: 2,
          name: '微毒(原药低毒)'
        },
        {
          id: 3,
          name: '微毒(原药高毒)'
        },
        {
          id: 4,
          name: '低毒'
        },
        {
          id: 5,
          name: '低毒(原药中等毒)'
        },
        {
          id: 6,
          name: '低毒(原药高毒)'
        },
        {
          id: 7,
          name: '低毒(原药剧毒)'
        },
        {
          id: 8,
          name: '中等毒'
        },
        {
          id: 9,
          name: '中等毒(原药高毒)'
        },
        {
          id: 10,
          name: '中等毒(原药剧毒)'
        },
        {
          id: 11,
          name: '高毒'
        },
        {
          id: 12,
          name: '高毒(原药剧毒)'
        },
        {
          id: 13,
          name: '剧毒'
        },
      ],
      typeVal: 0,
      typeValArr: [],
      typeValName: '',
      expirationDate: '',
      expirationDateUnit: 3,
      expirationDateUnitName: '年',
      expirationDateUnitArr: [
        {
          id: 1,
          name: '天'
        },
        {
          id: 2,
          name: '月'
        },
        {
          id: 3,
          name: '年'
        },
      ],
      entName: '',
      specColums: [
        {
          title: '规格图片',
          width: 110,
          slot: 'goodsPic',
        },
        {
          title: '规格',
          minWidth: 300,
          slot: 'spec',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '规格')
            ])
          }
        },
        {
          title: '价格',
          minWidth: 300,
          slot: 'price'
        },
        {
          title: '操作',
          width: 105,
          slot: 'operation',
        }
      ],
      specList: [
        {
          coverList: [],
          create: true
        }
      ],
      specObj: {
        id: 0,
        uuid: 0,
        pId: 0,
        level: 1,
        dosage: '',
        dosageUnit: 1,
        dosageUnitName: 'ml',
        numberUnit: 1,
        numberUnitName: '袋',
        price: ''
      },
      specObjList: [],
      specIndex: 0,
      specIsEdit: false,
      specVisible: false,
      uploadSpecCoverVisible: false,
      dosageUnitArr: [
        {
          id: 1,
          name: 'ml'
        },
        {
          id: 2,
          name: 'g'
        },
        {
          id: 3,
          name: '毫升'
        },
        {
          id: 4,
          name: '克'
        }
      ],
      numberUnitArr: [
        {
          id: 1,
          name: '袋'
        },
        {
          id: 2,
          name: '瓶'
        },
        {
          id: 3,
          name: '盒'
        },
        {
          id: 4,
          name: '桶'
        },
        {
          id: 5,
          name: '箱'
        }
      ],
      eleColums: [
        {
          title: '有效成分',
          minWidth: 400,
          slot: 'element',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '有效成分')
            ])
          }
        },
        {
          title: '有效成分含量',
          minWidth: 400,
          slot: 'content',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '有效成分含量')
            ])
          }
        },
        {
          title: '操作',
          width: 105,
          slot: 'operation',
        }
      ],
      eleList: [
        {
          element: '',
          content: ''
        }
      ],
      methodColums: [
        {
          title: '图例',
          width: 110,
          slot: 'uploadPic',
        },
        {
          title: '作物/场所',
          minWidth: 180,
          slot: 'crop',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '作物/场所')
            ])
          }
        },
        {
          title: '防治对象',
          minWidth: 180,
          slot: 'preventObject',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '防治对象')
            ])
          }
        },
        {
          title: '用药量(制剂量/亩)',
          minWidth: 180,
          slot: 'dosage',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '用药量(制剂量/亩)')
            ])
          }
        },
        {
          title: '施用方法',
          minWidth: 180,
          slot: 'method',
          renderHeader: (h, params) => {
            return h('p', [
              h('span', {
                style: {
                  color: '#D0021B'
                }
              }, '*'),
              h('span', '施用方法')
            ])
          }
        },
        {
          title: '操作',
          width: 105,
          slot: 'operation',
        }
      ],
      methodList: [
        {
          coverList: [],
          crop: '',
          preventObject: '',
          dosage: '',
          method: '',
        }
      ],
      methodIndex: 0,
      methodCoverIndex: 0,
      uploadMethodCoverVisible: false,
      property: '',
      notice: '',
      firstaid: '',
      transport: '',
      technology: ''
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getLicenseTypeList()
      this.getLicenseDosageList()
    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    // 只能输入数字和小数点
    formatFloat (str) {
      return str.replace(/[^\d.]/g, '')
    },
    // 数字转大写
    toChinesNum (num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] //changeNum[0] = "零"
      let unit = ['', '十', '百', '千', '万']
      num = parseInt(num)
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse()
        let newNum = ''
        for (var i = 0; i < strArr.length; i++) {
          newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
        }
        return newNum
      }
      let overWan = Math.floor(num / 10000)
      let noWan = num % 10000
      if (noWan.toString().length < 4) noWan = '0' + noWan
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)

    },
    // 获取默认图
    getDefaultCover (arr) {
      for (const item of arr) {
        if (item.isDefault) {
          return item
        }
      }
      return arr[0]
    },
    // 获取农药类别
    getLicenseTypeList () {
      getLicenseTypeList({type: 1}).then(res => {
        this.typeValArr = res.data
      })
    },
    // 获取剂型
    getLicenseDosageList () {
      getLicenseDosageList({type: 1}).then(res => {
        this.dosageValArr = res.data
      })
    },
    onChangeDosageVal (obj) {
      this.dosageValName = obj ? obj.label : ''
    },
    onChangeToxicity (obj) {
      this.toxicityName = obj ? obj.label : ''
    },
    onChangeTypeVal (obj) {
      this.typeValName = obj ? obj.label : ''
    },
    onChangeExpirationDateUnit (obj) {
      this.expirationDateUnitName = obj ? obj.label : ''
    },
    // 上传照片
    uploadCover () {
      this.imageList = JSON.parse(JSON.stringify(this.coverList))
      this.uploadCoverVisible = true
    },
    // 当上传图片弹窗关闭时
    onUploadCoverClose (status) {
      if (!status) {
        this.imageList = []
      }
    },
    // 上传图片成功
    uploadCoverSuccess (arr) {
      arr.forEach(item => {
        this.imageList.push({
          url: item.url,
          width: item.width,
          height: item.height,
          isDefault: this.imageList.length === 0
        })
      })
    },
    // 点击确定
    onClickConfirmCover () {
      this.coverList = this.imageList.map(item => item)
      this.uploadCoverVisible = false
    },
    // 点击取消
    onClickCancleCover () {
      this.uploadCoverVisible = false
    },
    // 上传视频
    uploadVideo () {
      this.videoDemo = JSON.parse(JSON.stringify(this.video))
      this.uploadVideoVisible = true
    },
    // 点击确定
    onClickConfirmVideo () {
      this.video = JSON.parse(JSON.stringify(this.videoDemo))
      this.uploadVideoVisible = false
    },
    // 点击取消
    onClickCancleVideo () {
      this.uploadVideoVisible = false
    },
    // 上传视频开始
    uploadVideoStart () {
      this.uploadVideoDisabled = true
      this.videoDemo.videoUrl = ''
      this.uploadVideoProgress = 0
    },
    // 视频上传进度
    getUploadVideoProgress (data) {
      this.uploadVideoProgress = Number.parseInt(data * 100) < 1 ? 1 : Number.parseInt(data * 100)
      if (this.uploadVideoProgress === 100) {
        this.uploadVideoProgress = 0
      }
    },
    // 上传视频成功
    uploadVideoSuccess (arr) {
      this.uploadVideoDisabled = false
      this.videoDemo.videoUrl = arr[0].videoUrl
      this.uploadVideoProgress = 0
    },
    // 上传视频封面成功
    uploadVideoCoverSuccess (arr) {
      this.videoDemo.coverUrl = arr[0].url
    },
    // 截取视频封面
    getVideoCover (url) {
      return url + '?x-oss-process=video/snapshot,t_1000,ar_auto'
    },
    // 上传规格图片
    uploadSpecCover (index) {
      this.specIndex = index
      this.imageList = JSON.parse(JSON.stringify(this.specList[index].coverList))
      this.uploadSpecCoverVisible = true
    },
    // 点击确定
    onClickConfirmSpecCover () {
      this.specList[this.specIndex].coverList = this.imageList.map(item => item)
      this.uploadSpecCoverVisible = false
    },
    // 点击取消
    onClickCancleSpecCover () {
      this.uploadSpecCoverVisible = false
    },
    // 获取规格描述
    getSpecDesc (index) {
      if (index === 0) {
        return this.specObj.dosage + this.specObj.dosageUnitName
      } else {
        let str = this.specObj.dosage + this.specObj.dosageUnitName
        for (let i = 0; i < index; i++) {
          str += '*' + this.specObjList[i].dosage + this.specObjList[i].dosageUnitName
        }
        return str
      }
    },
    // 修改剂量规格单位
    onChangeDoseSpecUnit (obj) {
      this.specObj.numberUnitName = obj.label
      if (this.specObjList.length > 0) {
        this.specObjList[0].dosageUnit = obj.value
        this.specObjList[0].dosageUnitName = obj.label
      }
    },
    // 修改包装规格单位
    onChangePackSpecUnit (obj, item, index) {
      item.numberUnitName = obj.label
      if (this.specObjList[index + 1]) {
        this.specObjList[index + 1].dosageUnit = obj.value
        this.specObjList[index + 1].dosageUnitName = obj.label
      }
    },
    // 点击编辑规格
    onClickEditSpec (index) {
      this.specIsEdit = true
      this.specIndex = index
      let arr = this.specList[index].childList.concat()
      this.specObj = arr.shift()
      this.specObjList = arr
      this.specVisible = true
    },
    // 点击添加规格
    onClickAddSpec () {
      let uuid = uuidv1().replace(/-/g, '')
      this.specIsEdit = false
      this.specVisible = true
      this.specObj = {
        id: 0,
        uuid: uuid,
        pId: '',
        level: 1,
        dosage: '',
        dosageUnit: 1,
        dosageUnitName: 'ml',
        numberUnit: 1,
        numberUnitName: '袋',
        price: ''
      }
      this.specObjList = []
    },
    // 点击删除规格
    onClickDeleteSpec (index) {
      this.specList.splice(index, 1)
      if (this.specList.length === 0) {
        this.specList = [
          {
            coverList: [],
            create: true
          }
        ]
      }
    },
    // 点击添加剂量规格
    onClickAddDoseSpec () {
      if (!this.specObj.dosage) return this.$Message.info('请完善当前规格信息')
      let uuid = uuidv1().replace(/-/g, '')
      this.specObjList = [{
        id: 0,
        uuid: uuid,
        pId: this.specObj.uuid,
        level: 2,
        dosage: '',
        dosageUnit: this.specObj.numberUnit,
        dosageUnitName: this.specObj.numberUnitName,
        numberUnit: 5,
        numberUnitName: '箱',
        price: ''
      }]
    },
    // 点击添加包装规格
    onClickAddPackSpec (item, index) {
      if (!item.dosage) return this.$Message.info('请完善当前规格信息')
      let uuid = uuidv1().replace(/-/g, '')
      this.specObjList.push({
        id: 0,
        uuid: uuid,
        pId: item.uuid,
        level: index + 3,
        dosage: '',
        dosageUnit: item.numberUnit,
        dosageUnitName: item.numberUnitName,
        numberUnit: 5,
        numberUnitName: '箱',
        price: ''
      })
    },
    // 点击删除规格child
    onClickDeleteSpecChild (item, index) {
      this.specObjList.splice(index, this.specObjList.length - index)
    },
    // 点击确定
    onClickConfirmSpec () {
      if (!this.specObj.dosage) return this.$Message.info('请完善当前规格信息')
      let arr = []
      arr.push(this.specObj)
      let obj
      if (this.specObjList.length > 0) {
        for (const item of this.specObjList) {
          if (!item.dosage) return this.$Message.info('请完善当前规格信息')
          arr.push(item)
        }
        let lastIndex = this.specObjList.length - 1
        let lastItem = this.specObjList[lastIndex]
        obj = {
          coverList: [],
          desc: this.getSpecDesc(lastIndex),
          dosage: lastItem.dosage,
          dosageUnitName: lastItem.dosageUnitName,
          numberUnitName: lastItem.numberUnitName,
          price: lastItem.price,
          childList: arr
        }
      } else {
        obj = {
          coverList: [],
          desc: '',
          dosage: this.specObj.dosage,
          dosageUnitName: this.specObj.dosageUnitName,
          numberUnitName: this.specObj.numberUnitName,
          price: this.specObj.price,
          childList: arr
        }
      }
      verifySpec(1, {
        qty: obj.childList[0].dosage,
        unit: obj.childList[0].dosageUnitName
      }).then(res => {
        if (res.code !== 0) {
          obj.noStandard = true
        } else {
          obj.noStandard = false
        }
        if (this.specIsEdit) {
          this.specList[this.specIndex].desc = obj.desc
          this.specList[this.specIndex].dosage = obj.dosage
          this.specList[this.specIndex].dosageUnitName = obj.dosageUnitName
          this.specList[this.specIndex].numberUnitName = obj.numberUnitName
          this.specList[this.specIndex].price = obj.price
          this.specList[this.specIndex].childList = obj.childList
          this.specList[this.specIndex].noStandard = obj.noStandard
          // this.$set(this.specList, this.specIndex, obj)
        } else {
          if (this.specList.length === 1 && this.specList[0].create) {
            this.specList = [obj]
          } else {
            this.specList.push(obj)
          }
        }
        this.specVisible = false
      })
    },
    // 点击取消
    onClickCancleSpec () {
      this.specVisible = false
    },
    // 添加有效成分
    addEle () {
      this.eleList.push({
        element: '',
        content: ''
      })
    },
    // 上传用药量图片
    uploadMethodCover (index) {
      this.methodIndex = index
      this.imageList = JSON.parse(JSON.stringify(this.methodList[index].coverList))
      this.uploadMethodCoverVisible = true
    },
    // 上传用药量图片成功
    uploadMethodCoverSuccess (arr) {
      arr.forEach(item => {
        this.imageList.push({
          url: item.url,
          width: item.width,
          height: item.height,
          title: '',
          desc: {
            content: ''
          },
          isDefault: this.imageList.length === 0
        })
      })
    },
    // 点击用药量图片
    onClickMethodCoverItem (index) {
      this.methodCoverIndex = index
    },
    // 点击确定
    onClickConfirmMethodCover () {
      this.methodList[this.methodIndex].coverList = this.imageList.map(item => item)
      this.uploadMethodCoverVisible = false
    },
    // 点击取消
    onClickCancleMethodCover () {
      this.imageList = []
      this.uploadMethodCoverVisible = false
    },
    // 添加用药量
    addMethod () {
      this.methodList.push({
        coverList: [],
        crop: '',
        preventObject: '',
        dosage: '',
        method: '',
      })
    },
    // 点击保存
    onClickConfirm () {
      if (!this.goodsName) return this.$Message.info('请填写商品名称')
      if (!this.pestName) return this.$Message.info('请填写农药名称')
      if (!this.element) return this.$Message.info('请填写总有效成分及含量')
      if (!this.regNumber) return this.$Message.info('请填写登记证号')
      if (!this.dosageVal) return this.$Message.info('请选择剂型')
      if (!this.toxicity) return this.$Message.info('请选择毒性')
      if (!this.typeVal) return this.$Message.info('请选择农药类别')
      if (!this.expirationDate) return this.$Message.info('请填写保质期')
      if (!this.entName.trim()) return this.$Message.info('请填写登记证持有人')
      for (const item of this.specList) {
        if (item.create) return this.$Message.info('请添加商品规格')
        if (item.noStandard) return this.$Message.info('商品规格不符合标准')
      }
      for (const item of this.eleList) {
        if (!item.element || !item.content) return this.$Message.info('请填写有效成分信息')
      }
      for (const item of this.methodList) {
        if (!item.crop || !item.preventObject || !item.dosage || !item.method) return this.$Message.info('请填写制剂用药量信息')
      }
      if (!this.technology) return this.$Message.info('请填写使用技术')
      if (!this.property) return this.$Message.info('请填写产品性能')
      if (!this.notice) return this.$Message.info('请填写注意事项')
      if (!this.firstaid) return this.$Message.info('请填写中毒急救')
      if (!this.transport) return this.$Message.info('请填写存储运输')
      let params = {
        type: 1,
        libraryId: this.libraryId,
        coverList: this.coverList.map(item => {
          return {
            coverType: 1,
            url: item.url,
            width: item.width,
            height: item.height,
            extension: JSON.stringify({isDefault: item.isDefault})
          }
        }),
        name: this.goodsName,
        extra: {
          useMethod: '',
          name: this.pestName,
          mainIngredient: this.element,
          serial: this.regNumber,
          dosageForm: this.dosageValName,
          toxicity: this.toxicityName,
          type: this.typeValName,
          ingredientList: this.eleList.map(item => {
            return {
              ingredient: item.element,
              content: item.content
            }
          }),
          dosageList: this.methodList.map(item => {
            return {
              descList: item.coverList.map(items => {
                return {
                  id: items.id || 0,
                  contentType: 2,
                  content: [{
                    url: items.url,
                    width: items.width,
                    height: items.height,
                    title: items.title,
                    desc: items.desc.content,
                    extension: JSON.stringify({isDefault: items.isDefault})
                  }]
                }
              }),
              applyCrop: item.crop,
              preventObject: item.preventObject,
              dosage: item.dosage,
              applyMethod: item.method
            }
          }),
          useMethod: this.technology,
          performance: this.property,
          notice: this.notice,
          poison: this.firstaid,
          store: this.transport
        },
        shelfLife: this.expirationDate + this.expirationDateUnitName,
        specList: this.specList.map(item => {
          return {
            coverList: item.coverList.map(items => {
              return {
                url: items.url,
                width: items.width,
                height: items.height,
                extension: JSON.stringify({isDefault: items.isDefault})
              }
            }),
            childSpecPack: item.desc,
            qty: item.dosage,
            unit: item.dosageUnitName,
            packageUnit: item.numberUnitName,
            salePrice: item.price,
            childList: item.childList.map(items => {
              return {
                id: items.id,
                specUuid: items.uuid,
                parentUuid: items.pId,
                level: items.level,
                qty: items.dosage,
                unit: items.dosageUnitName,
                packageUnit: items.numberUnitName,
                salePrice: items.price
              }
            })
          }
        }),
        holderList: [{
          entName: this.entName
        }]
      }
      if (this.video.videoUrl) {
        params.coverList.push({
          coverType: 2,
          url: this.video.videoUrl,
          coverImgUrl: this.video.coverUrl,
          title: this.video.videoTitle
        })
      }
      if (!this.goodsId) {
        createGoods(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        editGoods(this.goodsId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
    },
    // 点击清空数据
    onClickClear () {
      this.$Modal.confirm({
        title: '清空数据',
        content: '<p>是否清空数据？</p>',
        onOk: () => {
          this.clearCreateGoods()
        }
      })
    },
    // 清空新增商品
    clearCreateGoods () {
      this.libraryId = 0
      this.coverList = []
      this.imageList = []
      this.video = {
        id: 0,
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      }
      this.videoDemo = {
        id: 0,
        videoTitle: '',
        videoUrl: '',
        coverUrl: ''
      }
      this.uploadVideoProgress = 0
      this.goodsName = ''
      this.pestName = ''
      this.element = ''
      this.regNumber = ''
      this.dosageVal = 0
      this.dosageValName = ''
      this.toxicity = 0
      this.toxicityName = ''
      this.typeVal = 0
      this.typeValName = ''
      this.expirationDate = ''
      this.expirationDateUnit = 3
      this.expirationDateUnitName = '年'
      this.specList = [
        {
          coverList: [],
          create: true
        }
      ]
      this.specIndex = 0
      this.eleList = [
        {
          element: '',
          content: ''
        }
      ]
      this.methodList = [
        {
          coverList: [],
          crop: '',
          preventObject: '',
          dosage: '',
          method: '',
        }
      ]
      this.methodIndex = 0
      this.property = ''
      this.technology = ''
      this.notice = ''
      this.firstaid = ''
      this.transport = ''
      this.entName = ''
    },
    // 获取商品详情
    getGoodsDetail () {
      getGoodsDetail(this.goodsId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.libraryId = data.libraryId
          data.coverList.forEach(item => {
            if (item.coverType === 1) {
              this.coverList.push({
                url: item.url || '',
                width: item.width || '',
                height: item.height || '',
                isDefault: item.extension ? JSON.parse(item.extension).isDefault : ''
              })
            } else {
              this.video = {
                videoTitle: item.title || '',
                videoUrl: item.url || '',
                coverUrl: item.coverImgUrl || ''
              }
            }
          })
          this.goodsName = data.name || ''
          this.pestName = data.extra.name || ''
          this.element = data.extra.mainIngredient || ''
          this.regNumber = data.extra.serial || ''
          let findResult = this.dosageValArr.find(item => {
            return item.name === data.extra.dosageForm
          })
          this.dosageVal = findResult ? findResult.id || 0 : 0
          this.dosageValName = data.extra.dosageForm || ''
          let toxicityResult = this.toxicityArr.find(item => {
            return item.name === data.extra.toxicity
          })
          this.toxicity = toxicityResult ? toxicityResult.id || 0 : 0
          this.toxicityName = data.extra.toxicity || ''
          let typeValArrResult = this.typeValArr.find(item => {
            return item.name === data.extra.childTypeNameList[0]
          })
          this.typeVal = typeValArrResult ? typeValArrResult.id || 0 : 0
          this.typeValName = data.extra.childTypeNameList[0] || ''
          if (data.shelfLife) {
            this.expirationDate = data.shelfLife.replace(/[^0-9]/ig, '') || ''
            this.expirationDateUnitName = data.shelfLife.replace(/[^\u4e00-\u9fa5]/ig, '') || ''
            let expirationDateUnitArrResult = this.expirationDateUnitArr.find(item => {
              return item.name === this.expirationDateUnitName
            })
            this.expirationDateUnit = expirationDateUnitArrResult ? expirationDateUnitArrResult.id || 1 : 1
          }
          if (data.specList.length > 0) {
            this.specList = data.specList.map(item => {
              return {
                coverList: item.coverList.map(items => {
                  return {
                    url: items.url,
                    width: items.width,
                    height: items.height,
                    isDefault: items.extension ? JSON.parse(items.extension).isDefault : false
                  }
                }),
                desc: item.childSpecPack || '',
                dosage: item.qty || '',
                dosageUnitName: item.unit || '',
                numberUnitName: item.packageUnit || '',
                price: item.salePrice || '',
                childList: item.childList.map(items => {
                  let t = this.numberUnitArr.find(itemss => {
                    return itemss.name === items.packageUnit
                  })
                  let obj = {
                    id: items.specId || 0,
                    uuid: items.specUuid || 0,
                    pId: items.parentUuid || '',
                    level: items.level || 1,
                    dosage: items.qty || '',
                    dosageUnitName: items.unit || '',
                    numberUnit: t ? t.id || 1 : 1,
                    numberUnitName: items.packageUnit || '',
                    price: items.salePrice || ''
                  }
                  if (obj.level === 1) {
                    obj.dosageUnit = this.dosageUnitArr.find(itemss => {
                      return itemss.name === items.unit
                    }).id || 1
                  } else {
                    obj.dosageUnit = this.numberUnitArr.find(itemss => {
                      return itemss.name === items.unit
                    }).id || 1
                  }
                  return obj
                })
              }
            })
          }
          if (data.extra.ingredientList.length > 0) {
            this.eleList = data.extra.ingredientList.map(item => {
              return {
                element: item.ingredient || '',
                content: item.content || ''
              }
            })
          }
          if (data.extra.dosageList.length > 0) {
            this.methodList = data.extra.dosageList.map(item => {
              return {
                coverList: item.descList.map(items => {
                  return {
                    id: items.id || 0,
                    url: items.content[0].url || '',
                    width: items.content[0].width || '',
                    height: items.content[0].height || '',
                    title: items.content[0].title || '',
                    desc: {
                      content: items.content[0].desc || ''
                    },
                    isDefault: items.content[0].extension ? JSON.parse(items.content[0].extension).isDefault : false
                  }
                }),
                crop: item.applyCrop || '',
                preventObject: item.preventObject || '',
                dosage: item.dosage || '',
                method: item.applyMethod || '',
              }
            })
          }
          this.technology = data.extra.useMethod || ''
          this.property = data.extra.performance || ''
          this.notice = data.extra.notice || ''
          this.firstaid = data.extra.poison || ''
          this.transport = data.extra.store || ''
          if (data.holderList.length > 0) {
            this.entName = data.holderList[0].entName || ''
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .main {
    height: 100%;
    background-color: #f9f9ff;

    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 20px;

      .topbar-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: #666;

        &:hover {
          color: #0652dd;
        }
      }

      ::v-deep {
        .ivu-select-selection,
        .ivu-input {
          background-color: transparent;
          border-color: #9c9ccd;
          border-radius: 18px;
        }

        .ivu-select-selection span {
          padding-left: 12px;
        }

        .ivu-input {
          padding: 4px 12px;
        }
      }
    }
  }

  .create {
    height: calc(100% - 160px);
    overflow-y: scroll;
    background-color: #fff;
    display: flex;
    margin: 20px;
    padding: 25px;

    .create-title {
      font-size: 14px;
      line-height: 32px;
      color: #0652dd;
    }

    ::v-deep .ivu-table .ivu-table-header th {
      background-color: #e4edfe;
    }
  }

  .create-upload {
    width: 240px;
    height: 180px;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 14px;
    }

    .create-upload-img {
      width: 100%;
      height: 100%;
      position: relative;

      span {
        position: absolute;
        right: 8px;
        bottom: 8px;
        padding: 0 16px;
        line-height: 28px;
        color: #fff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 16px;
      }

      .create-upload-mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .create-upload-del {
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  ::v-deep .ivu-select-dropdown-list {
    max-height: 300px;
  }

  ::v-deep .ivu-drawer {
    top: 64px;
    z-index: 1009;

    .ivu-drawer-body {
      padding: 0;
    }
  }

  .uploadPic {
    padding-left: 20px;

    .uploadPic-title {
      font-size: 16px;
    }

    .uploadPic-btn {
      display: flex;
      justify-content: center;
    }
  }

  .uploadVideo {
    display: flex;
    flex-direction: column;
    align-items: center;

    .uploadVideo-con {
      width: 500px;
    }

    .uploadVideo-btn {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }

  .specModal {
    max-height: 600px;
    overflow-y: scroll;

    .specModal-ul {
      width: 560px;
      margin: 0 auto;

      .specModal-li {
        padding: 15px 20px;
        border-bottom: 1px solid #d8d8d8;

        .specModal-title {
          width: 98px;
          font-size: 14px;
          text-align: right;
        }

        ::v-deep {
          .ivu-input[disabled],
          .ivu-select-disabled .ivu-select-selection {
            background-color: #fff;
            color: #333;
          }

          .ivu-input-group-prepend .ivu-select-selection,
          .ivu-input-group-append .ivu-select-selection {
            border: 1px solid #dcdee2;
          }
        }
      }
    }

    .specModal-btn {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }

  .method-upload {
    height: calc(100% - 160px);
    background-color: #fff;
    overflow-y: scroll;
    margin: 20px;
    padding: 20px;
    display: flex;

    .method-upload-ul {
      .method-upload-li {
        display: flex;
        margin-bottom: 20px;

        .method-upload-title {
          flex-shrink: 0;
          font-size: 16px;
          line-height: 36px;
          margin-right: 10px;
        }

        input {
          width: 100%;
          height: 36px;
          padding: 0 10px;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }
</style>
