<template>
  <div>
    <Drawer :mask-closable="false"
            :closable="false"
            :mask="false"
            :transfer="false"
            inner
            scrollables
            :width="width"
            :value="visible">
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="onClickClose">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回
            </div>
          </div>
        </div>
        <div class="goods">
          <ul>
            <li @click.stop="onClickTraceInfo">
              <div class="goods-icon">
                <Icon custom="i-icon icon-zhuisu"
                      size="50"></Icon>
              </div>
              <span>追溯信息</span>
            </li>
            <li>
              <div class="goods-icon">
                <Icon custom="i-icon icon-producnt"
                      size="50"></Icon>
              </div>
              <span>商品信息</span>
            </li>
            <li>
              <div class="goods-icon">
                <Icon custom="i-icon icon-ewcode"
                      size="50"></Icon>
              </div>
              <span>商品二维码</span>
            </li>
          </ul>
        </div>
      </div>
    </Drawer>
    <GoodsCreateDrawer :width="width"
                       v-model="createVisible"
                       :goodsId.sync="createGoodsId"
                       @on-click-confirm="onClickConfirm"></GoodsCreateDrawer>
    <GoodsDetailDrawer :width="width"
                       v-model="detailVisible"
                       :goodsId.sync="detailGoodsId"
                       @on-click-edit="onClickDetailEdit"
                       @on-click-delete="onClickDetailDelete"></GoodsDetailDrawer>
    <!-- 商品主图弹窗 -->
    <Modal v-model="codeVisible"
           title="上传图片"
           footer-hide
           @on-visible-change="clearCodeUrl"
           width="560">
      <div class="codeModal">
      </div>
    </Modal>
  </div>
</template>

<script>
import { getGoodsDetail, deleteGoods } from '../../services/goodsapi';
import GoodsCreateDrawer from './GoodsCreateDrawer';
import GoodsDetailDrawer from './GoodsDetailDrawer';
export default {
  components: { GoodsCreateDrawer, GoodsDetailDrawer },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    goodsId: {
      type: Number,
      default: 0
    },
    type: Number //1新增 2编辑 3查看
  },
  watch: {

  },
  data () {
    return {
      /* 新建商品 */
      createVisible: false,
      createGoodsId: 0,
      /* 商品详情 */
      detailVisible: false,
      detailGoodsId: 0,

      codeVisible: false,
      codeUrl: ''
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
    },
    onClickTraceInfo () {
      if (this.type === 1) {
        this.createGoodsId = 0
        this.createVisible = true
        this.$emit('change', false)
      } else {
        this.getGoodsDetail()
      }
    },
    // 获取商品详情
    getGoodsDetail () {
      getGoodsDetail(this.goodsId).then(res => {
        if (res.code === 0) {
          let data = res.data
          if (this.type === 3) {
            if (!data.name || !data.extra.name || !data.extra.mainIngredient || !data.extra.serial || !data.extra.dosageForm || !data.extra.toxicity || !data.extra.type || !data.shelfLife || !data.extra.performance || !data.extra.notice || !data.extra.poison || !data.extra.store) {
              this.createVisible = true
              this.createGoodsId = data.id
              this.$emit('change', false)
              return
            }
            if (data.holderList.length > 0) {
              if (!data.holderList[0].entName) {
                this.createVisible = true
                this.createGoodsId = data.id
                this.$emit('change', false)
                return
              }
            } else {
              this.createVisible = true
              this.createGoodsId = data.id
              this.$emit('change', false)
              return
            }
            for (const item of data.specList) {
              if (!item.qty) {
                this.createVisible = true
                this.createGoodsId = data.id
                this.$emit('change', false)
                return
              }
            }
            for (const item of data.extra.ingredientList) {
              if (!item.ingredient || !item.content) {
                this.createVisible = true
                this.createGoodsId = data.id
                this.$emit('change', false)
                return
              }
            }
            for (const item of data.extra.dosageList) {
              if (!item.applyCrop || !item.preventObject || !item.dosage || !item.applyMethod) {
                this.createVisible = true
                this.createGoodsId = data.id
                this.$emit('change', false)
                return
              }
            }
            this.detailVisible = true
            this.detailGoodsId = data.id
            this.$emit('change', false)
          } else {
            this.createVisible = true
            this.createGoodsId = data.id
            this.$emit('change', false)
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    clearCodeUrl (status) {
      if (!status) {
        this.codeUrl = ''
      }
    },
    // 点击保存
    onClickConfirm () {
      this.createVisible = false
      this.$emit('change', false)
      this.$emit('on-click-confirm')
    },
    // 点击详情内的编辑
    onClickDetailEdit () {
      this.detailVisible = false
      this.createVisible = true
      this.createGoodsId = this.detailGoodsId
    },
    // 点击详情内的删除
    onClickDetailDelete () {
      this.$Modal.confirm({
        title: '删除商品',
        content: '<p>是否删除商品？</p>',
        onOk: () => {
          let params = {
            goodsType: 1,
            idList: [this.goodsId].join()
          }
          deleteGoods(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.$emit('on-click-delete')
              this.detailVisible = false
              this.$emit('change', false)
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
  }
  .goods {
    height: calc(100% - 72px);
    display: flex;
    justify-content: center;
    ul {
      width: 860px;
      display: flex;
      justify-content: space-between;
      margin-top: 200px;
      li {
        text-align: center;
        cursor: pointer;
        .goods-icon {
          width: 128px;
          height: 128px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #0652dd;
          color: #0652dd;
        }
        span {
          font-size: 18px;
          display: block;
          margin-top: 20px;
        }
        &:hover {
          .goods-icon {
            background-color: #0652dd;
            color: #fff;
          }
          span {
            color: #0652dd;
          }
        }
      }
    }
  }
}
::v-deep .ivu-drawer {
  top: 64px;
  z-index: 1007;
  .ivu-drawer-body {
    padding: 0;
  }
}
</style>